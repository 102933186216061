{
  "name": "eule",
  "version": "0.5.9",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:local": "ng serve --configuration local",
    "build": "ng build",
    "build-develop": "ng build --configuration develop",
    "build-integration": "ng build --configuration integration",
    "build-staging": "ng  build --configuration staging",
    "build-production": "ng  build --configuration production",
    "deploy-develop": "firebase deploy --only hosting:develop",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test-ci": "ng test --no-watch --no-progress --browsers=ChromeHeadless",
    "test-e2e": "cypress run --headless",
    "lint": "ng lint"
  },
  "private": true,
  "author": "Severin Klug; Christian Sanker; Manuel Grauke",
  "license": "proprietary",
  "dependencies": {
    "@amcharts/amcharts5": "^5.10.4",
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.0.0",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/fire": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/material": "^17.0.0",
    "@angular/material-moment-adapter": "^17.0.2",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@botom/quill-resize-module": "^2.0.1",
    "@eeule/eeule-shared": "^0.15.3",
    "@ngrx/component-store": "^17.2.0",
    "@ssumo/quill-resize-module": "^1.0.3",
    "@stripe/stripe-js": "^4.9.0",
    "chart.js": "^4.4.0",
    "cors": "^2.8.5",
    "firebase": "^10.7.1",
    "firebase-functions": "^4.7.0",
    "firebase-tools": "^13.7.3",
    "moment": "^2.29.4",
    "ngx-image-cropper": "^7.2.1",
    "ngx-quill": "25.3.2",
    "quill": "^2.0.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.0",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.2.0",
    "@angular/compiler-cli": "^17.0.0",
    "@types/chart.js": "^2.9.41",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "@types/uuid": "^9.0.7",
    "@typescript-eslint/eslint-plugin": "6.19.0",
    "@typescript-eslint/parser": "6.19.0",
    "cypress": "^13.15.0",
    "cypress-file-upload": "^5.0.8",
    "eslint": "^8.56.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
