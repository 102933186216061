<mat-card class="custom-card">
  <mat-card-header>
    <mat-card-title>Sind Sie neu bei uns?</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="card-text">
      <p>Willkommen auf der eeule Plattform</p>
      <p>Wir wissen, dass die Nutzung einer neuen Software komplex sein kann und es viele neue Funktionen zu entdecken gibt.</p>
      <p>
        Gerne helfen wir Ihnen in einem persönlichen Webinar die eeule Plattform kennenzulernen. In dem Termin geben wir Ihnen einen
        umfassenden Überblick wie Sie eeule optimal nutzen können, um Ihre Ziele einfach und effizient zu erreichen.
      </p>
      <p>
        <button
          mat-raised-button
          (click)="openWebsite('https://outlook.office365.com/book/eeule1@eeule.de/s/b_rnG3YUAUu0XydY5IBNlg2')"
          color="primary"
          class="no-shadow"
          data-cy="button-qa-page-book-webinar"
        >
          <mat-icon>contact_phone</mat-icon>
          Persönliches Webinar buchen
        </button>
      </p>
    </div>
  </mat-card-content>
</mat-card>

<div class="spacer"></div>

<mat-card class="custom-card">
  <mat-card-header>
    <mat-card-title>Haben Sie Fragen an uns?</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="card-text">
      <p>
        Gibt es bestimmte Punkte, an denen Sie nicht weiterkommen oder gibt es Themen, die die Umsetzung eines Zertifizierungsprojektes
        blockieren?
      </p>
      <p>Dann vereinbaren Sie gerne einen Termin mit uns, in dem wir auf Ihre Fragen konkret eingehen werden.</p>
      <p>
        <button
          mat-raised-button
          (click)="openWebsite('https://outlook.office365.com/owa/calendar/eeule1@eeule.de/bookings/s/s0p-ogLufkueExhvYOZEPA2')"
          color="primary"
          class="no-shadow"
          data-cy="button-qa-page-book-appointment"
        >
          <mat-icon>contact_phone</mat-icon>
          Termin buchen
        </button>
      </p>
    </div>
  </mat-card-content>
</mat-card>
