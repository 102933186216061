import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { CertificationType, DgnbSystem, Project, UsageProfile } from '@eeule/eeule-shared';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { parseNumberAsLocaleString } from '../../../util/conversion.helper';
import { getLocaleDateString } from '../../../util/date.helper';
import { getUsageProfileEnumValue } from '../../../util/enum.helper';
import { BaseComponent } from '../../core/components/base/base.component';
import { GeneralTitleComponent } from '../../core/components/general-title/general-title.component';
import { ImageViewDialogComponent, ImageViewDialogData } from '../../core/components/image-view-dialog/image-view-dialog.component';
import { LabelValueCardComponent, LabelValueField } from '../../core/components/label-value-card/label-value-card.component';
import { LeafIconData } from '../../core/components/leaf-icon-list/leaf-icon-list.component';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';
import { IndicatorService } from '../../core/services/indicator.service';
import { PermissionService } from '../../core/services/permission.service';
import { ProjectService, StripeInfo } from '../../core/services/project.service';
import { SnackbarService } from '../../core/services/snackbar.service';
import { StorageService } from '../../core/services/storage.service';
import { StripeCustomerPortalSession, StripeService } from '../../core/services/stripe/stripe.service';
import { SystemConfigService } from '../../core/services/systemConfig/system-config.service';
import { UsageProfileService } from '../../core/services/usage-profiles/usage-profile.service';
import { UserService } from '../../core/services/user.service';
import { LifeCyclePhaseEnum } from '../../enums/LifeCyclePhase.enum';

export const CY_HOME_BUTTON_CUSTOMERPORTAL = 'home.button.customer_portal';

@Component({
  selector: 'eule-home',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    GeneralTitleComponent,
    LabelValueCardComponent,
    MatProgressSpinner,
    MatButtonModule,
    MatIconModule,
    MatTooltip,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent extends BaseComponent implements OnInit {
  CY_HOME_BUTTON_CUSTOMERPORTAL = CY_HOME_BUTTON_CUSTOMERPORTAL;

  public isCustomerPortalLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** Project image subject */
  projectImage$?: Observable<string | null>;

  projectImage?: string | null;

  /** Data for the different sections */
  generalData?: LabelValueField[];
  buildingParameterData?: LabelValueField[];
  certificationData?: LabelValueField[];

  isLoading: boolean = false;

  public constructor(
    public _permissionService: PermissionService,
    private _analyticsService: AnalyticsService,
    private _dialog: MatDialog,
    private _indicatorService: IndicatorService,
    private _projectService: ProjectService,
    private _snackbarService: SnackbarService,
    private _storageService: StorageService,
    private _stripeService: StripeService,
    private _systemConfigService: SystemConfigService,
    private _usageProfileService: UsageProfileService,
    private _userService: UserService
  ) {
    super();
    this._analyticsService.sendPageView('Home-Page');
  }

  /**
   * Initializes the component and loads project data.
   *
   * This method sets the loading state, subscribes to the project observable, and fetches
   * necessary data such as project image, project owner, usage profiles, DGNB system, and
   * certification levels. It handles errors and updates the component state accordingly.
   */
  ngOnInit() {
    this.projectImage$ = this._projectService.project$.pipe(
      distinctUntilChanged(),
      switchMap(project => {
        if (!project) return of(null);
        return project.projectImagePath
          ? this._storageService.downloadImage(project.projectImagePath)
          : of('./assets/images/Rendering_Mehrfamilienhaus3.jpeg');
      }),
      tap(imageUrl => (this.projectImage = imageUrl)),
      shareReplay(1)
    );

    this._projectService.project$
      .pipe(
        tap(() => (this.isLoading = true)),
        switchMap(project => {
          if (!project) return combineLatest([of(null), of(null), of(null)]);
          return combineLatest([
            of(project),
            this._projectService.getProjectUsers(project.id).pipe(
              switchMap(users => {
                if (!users) {
                  return of(null);
                }
                return this._projectService.getProjectUsersWithRolesAndRoleAssignments(project.id);
              }),
              switchMap(users => {
                if (!users) {
                  return of(null);
                }
                const projectOwners = users.filter(user => {
                  return user.roles?.some(role => role === 'owner') && user.authUserId;
                });
                if (!projectOwners[0]) return of(null);
                const ownersData$ = projectOwners
                  .filter(user => user.authUserId)
                  .map(owner => {
                    return this._userService.getUser(owner.authUserId!).pipe(
                      map(user => {
                        return user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : user?.email || '-';
                      })
                    );
                  });
                return forkJoin(ownersData$);
              })
            ),
            this._usageProfileService.getUsageProfiles(),
            this._indicatorService.getDgnbSystem(project.dgnbSystem),
            this._systemConfigService.getLiveSystemConfigTypesProjectCertificationLevels(),
            this._systemConfigService.getLiveSystemConfigTypesEuTaxonomy(),
            this._systemConfigService.getLiveSystemConfigTypesQng(),
          ]);
        }),
        catchError(error => {
          this._snackbarService.showErrorMessage('Error loading project');
          console.error(error);
          return throwError(() => error);
        }),
        tap(() => (this.isLoading = false)),
        takeUntil(this.stop$)
      )
      .subscribe(
        ([project, projectOwners, usageProfiles, dgnbSystem, certificationLevels, esgCertificationLevels, qngCertificationLevels]) => {
          if (!project) return; // return gracefully if no project was emitted and wait for project emission. (Project fetch has it`s own error handling).
          this.generateSectionData(
            project,
            projectOwners || [],
            dgnbSystem,
            usageProfiles?.data,
            certificationLevels,
            esgCertificationLevels,
            qngCertificationLevels
          );
        }
      );
  }

  /**
   * Handles the click event on the project image.
   *
   * This method opens a dialog to display the project image.
   */
  onProjectImageClick() {
    this.openProjectImageDialog();
  }

  /**
   * Handles the keydown event on the project image.
   *
   * This method checks if the pressed key is 'Enter' or 'Space'. If so, it opens a dialog to display the project image
   * and prevents the default action of the event.
   *
   * @param {KeyboardEvent} event - The keyboard event object.
   */
  onProjectImagKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.openProjectImageDialog();
      event.preventDefault();
    }
  }

  private openProjectImageDialog() {
    if (!this.projectImage) {
      console.error('project or dummy image expected but was not provided');
      return; // return gracefully
    }
    this._dialog.open<ImageViewDialogComponent, ImageViewDialogData>(ImageViewDialogComponent, {
      data: {
        imageUrl: this.projectImage,
      },
      height: '60vh',
    });
  }

  /**
   * Generates data for different sections of the project.
   *
   * This function calls other functions to generate general data, building parameters data,
   * and certification data for the project.
   *
   * @param {Project} project - The project data object.
   * @param {string[]} owners - The owner of the project, or null if not available.
   * @param {DgnbSystem} dgnbSystem - The DGNB system data object.
   * @param {UsageProfile[] | null} [usageProfiles] - The array of usage profiles, or null/undefined if not available.
   * @param {CertificationType[] | undefined} [certificationLevels] - The array of certification levels, or undefined if not available.
   * @param {CertificationType[] | undefined} [esgCertificationLevels] - The array of ESG certification levels, or undefined if not available.
   * @param {CertificationType[] | undefined} [qngCertificationLevels] - The array of QNG certification levels, or undefined if not available.
   */
  private generateSectionData(
    project: Project,
    owners: string[],
    dgnbSystem: DgnbSystem,
    usageProfiles?: UsageProfile[] | null,
    certificationLevels?: CertificationType[],
    esgCertificationLevels?: CertificationType[],
    qngCertificationLevels?: CertificationType[]
  ): void {
    this.generateGeneralData(project, owners);
    this.generateBuildingParametersData(project, usageProfiles);
    this.generateCertificationData(project, dgnbSystem, certificationLevels, esgCertificationLevels, qngCertificationLevels);
  }

  /**
   * Generates general data for the project.
   *
   * This function creates an array of label-value pairs containing general information about the project.
   * It includes the project name, number, owner, location, application number, and time interval.
   *
   * @param {Project} project - The project data object.
   * @param {string[]} owners - The owner of the project, or null if not available.
   */
  private generateGeneralData(project: Project, owners: string[]) {
    const startDate: string | null = project.leistungsPhasen?.[0] ? getLocaleDateString(project.leistungsPhasen[0], true) : null;

    const endDate: string | null = project.handoverDate ? getLocaleDateString(project.handoverDate, true) : null;

    let timeIntervalString: string = '-';
    if (startDate && endDate) {
      timeIntervalString = `${startDate} - ${endDate}`;
    } else if (startDate) {
      timeIntervalString = `ab ${startDate}`;
    } else if (endDate) {
      timeIntervalString = `bis ${endDate}`;
    }

    this.generalData = [
      { label: 'Projektname', value: project.name || '-' },
      { label: 'Projektnummer', value: project.number || '-' },
      { label: 'Eigentümer', value: owners.join('<br>'), useHTML: true },
      { label: 'Standort', value: project.addressCity || '-' },
      { label: 'Antragsnummer', value: project.applicationNumber || '-' },
      { label: 'Zeitraum', value: timeIntervalString || '-' },
    ];
  }

  /**
   * Generates building parameters data for the project.
   *
   * This function calculates the total values for BGF, KGF, NRF, and BRI from the project's usage profiles.
   * It also generates an array of usage profile strings and creates an array of label-value pairs containing
   * building parameters information such as number of floors, construction costs, and residential units.
   *
   * @param {Project} project - The project data object.
   * @param {UsageProfile[] | null | undefined} usageProfiles - The array of usage profiles, or null/undefined if not available.
   */
  private generateBuildingParametersData(project: Project, usageProfiles: UsageProfile[] | null | undefined) {
    const bgf: number = project.usageProfiles?.map(profile => profile.bgf).reduce((acc, val) => (acc || 0) + (val || 0), 0) || 0;

    const kgf: number = project.usageProfiles?.map(profile => profile.kgf).reduce((acc, val) => (acc || 0) + (val || 0), 0) || 0;

    const nrf: number = project.usageProfiles?.map(profile => profile.nrf).reduce((acc, val) => (acc || 0) + (val || 0), 0) || 0;

    const bri: number = project.usageProfiles?.map(profile => profile.bri).reduce((acc, val) => (acc || 0) + (val || 0), 0) || 0;

    const usageProfileLeafIcons: LeafIconData[] | undefined = project.usageProfiles
      ?.map(profile => {
        const upString: string | undefined = usageProfiles?.find(up => up.id === profile.usageProfile)?.name;
        if (!upString) return undefined;
        const leafValue: string | undefined = getUsageProfileEnumValue(upString);
        return { value: leafValue?.slice(0, 2).toUpperCase() || '', tooltip: leafValue || '' };
      })
      .filter(leafIconData => leafIconData?.value?.length) as LeafIconData[] | undefined;

    this.buildingParameterData = [
      { label: 'Geschosse', value: project.numberOfFloors?.toString() || '-' },
      {
        label: 'Baukosten',
        value: project.costs ? parseNumberAsLocaleString(parseFloat(project.costs?.toString())) : '-',
      },
      { label: 'BGR (R) Gesamt [m2]', value: parseNumberAsLocaleString(bgf) },
      { label: 'KGF (R) Gesamt [m2]', value: parseNumberAsLocaleString(kgf) },
      { label: 'Wohneinheiten', value: project.numberOfResidentialUnits?.toString() || '-' },
      {
        label: 'Nutzungsprofile',
        value: usageProfileLeafIcons?.length ? usageProfileLeafIcons : '-',
        isLeafIconArray: !!usageProfileLeafIcons?.length,
      },
      { label: 'NRF (R) Gesamt [m2]', value: parseNumberAsLocaleString(nrf) },
      { label: 'BRI Gesamt [m3]', value: parseNumberAsLocaleString(bri) },
    ];
  }

  /**
   * Generates certification data for the project.
   *
   * This function creates an array of label-value pairs containing certification information about the project.
   * It includes details such as the life cycle phase, BGF size, certification level, ESG verification, certification system,
   * deconstruction status, DGNB system, and quality seal for sustainable buildings.
   *
   * @param {Project} project - The project data object.
   * @param {DgnbSystem} dgnbSystem - The DGNB system data object.
   * @param {CertificationType[] | undefined} certificationLevels - The array of certification levels, or undefined if not available.
   * @param {CertificationType[] | undefined} esgCertificationLevels - The array of ESG certification levels, or undefined if not available.
   * @param {CertificationType[] | undefined} qngCertificationLevels - The array of QNG certification levels, or undefined if not available.
   */
  private generateCertificationData(
    project: Project,
    dgnbSystem: DgnbSystem,
    certificationLevels?: CertificationType[],
    esgCertificationLevels?: CertificationType[],
    qngCertificationLevels?: CertificationType[]
  ) {
    const certificationLevel: CertificationType | undefined = certificationLevels?.find(
      level => level.id === project.selectedCertificationLevel
    );

    //FIXME! wrong typing -> type mismatch db <-> Project.interface
    const esgCertificationLevel: CertificationType | undefined = esgCertificationLevels?.find(
      level => level.id === (project.selectedErfuellungsGradEuTaxonomy as unknown as string)
    );

    //FIXME! wrong typing -> type mismatch db <-> Project.interface
    const qngCertificationLevel: CertificationType | undefined = qngCertificationLevels?.find(
      level => level.id === (project.selectedErfuellungsGradQng as unknown as string)
    );

    this.certificationData = [
      {
        label: 'Bauphase',
        value: project.lifeCyclePhase ? LifeCyclePhaseEnum[project.lifeCyclePhase as unknown as keyof typeof LifeCyclePhaseEnum] : '-',
      },
      { label: 'BGF > 5.000 m2', value: project.bgfBigger5000 ? 'Ja' : 'Nein' },
      { label: 'Zertifikatsstufe', value: certificationLevel?.value || '-' },
      { label: 'Qualitätssiegel Nachhaltige Gebäude', value: qngCertificationLevel?.value || 'Nein' },
      { label: 'Zertifizierungssystem', value: dgnbSystem.name || '-' },
      { label: 'Mit Rückbau', value: project.withDeconstruction || project.circularEconomy ? 'Ja' : 'Nein' },
      { label: 'DGNB', value: project.selectedErfuellungsGradSystem?.name || '-' },
      { label: 'ESG-Verifikation', value: esgCertificationLevel?.value || '-' },
    ];
  }

  public openCustomerPortal() {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: CY_HOME_BUTTON_CUSTOMERPORTAL,
    });

    this.isCustomerPortalLoading$.next(true);
    this._projectService
      .getStripeInfo()
      .pipe(
        switchMap((info: StripeInfo | null) => {
          if (info) {
            return this._stripeService.createCustomerPortalSession(info.customerId, this._projectService.project$.value!.id);
          } else {
            return EMPTY;
          }
        }),
        tap((session: StripeCustomerPortalSession | null) => {
          window.open(session?.url, '_self');
        }),
        catchError(err => {
          this.isCustomerPortalLoading$.next(false);
          return err;
        })
      )
      .subscribe(() => {
        this.isCustomerPortalLoading$.next(false);
      });
  }
}
