import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { RoleAssignment, RoleId, UserStatus } from '@eeule/eeule-shared';
import { doc } from 'firebase/firestore';
import { lastValueFrom, take } from 'rxjs';
import { getLocaleDateString, timeStampToDate } from '../../../../util/date.helper';
import { userRoles, userStatus } from '../../../../util/translation.helper';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { MatTableBaseComponent } from '../../../core/components/mat-table-base/mat-table-base.component';
import { TypeSafeMatCellDef } from '../../../core/directives/TypeSafeMatCellDef';
import { ReplacePipe } from '../../../core/pipes/replace.pipe';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { FirebaseService } from '../../../core/services/firebase.service';
import { PermissionService } from '../../../core/services/permission.service';
import { ProjectService } from '../../../core/services/project.service';
import { RoleService } from '../../../core/services/role.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { ProjectUserDisplay, UserService } from '../../../core/services/user.service';
import { MatSelectOption } from '../../../types/common-types';
import { FirebaseDocumentData } from '../../../types/firebase-types';
import { UserListTableData } from '../../home/users-list-page/users-list-page.component';
import { RoleOptionDisabledPipe } from './pipes/role-option-disabled.pipe';

export const CY_LICENSEADMINISTRATION_BUTTON_MENU = 'license_administration.button.menu';
export const CY_LICENSEADMINISTRATION_BUTTON_DELETE = 'license_administration.button.delete';
export const CY_LICENSEADMINISTRATION_BUTTON_DEACTIVATE = 'license_administration.button.deactivate';
export const CY_LICENSEADMINISTRATION_BUTTON_ADDOWNERSHIP = 'license_administration.button.add_ownership';
export const CY_LICENSEADMINISTRATION_BUTTON_REMOVEOWNERSHIP = 'license_administration.button.remove_ownership';
export const CY_LICENSEADMINISTRATION_SELECT_ROLE = 'license_administration.select.role';

@Component({
  selector: 'eule-license-administration',
  standalone: true,
  imports: [
    AsyncPipe,
    MatCell,
    MatCellDef,
    MatCheckbox,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatNoDataRow,
    MatOption,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatSelect,
    MatSort,
    MatSortHeader,
    MatTable,
    NgClass,
    ReplacePipe,
    RoleOptionDisabledPipe,
    TypeSafeMatCellDef,
  ],
  templateUrl: './license-administration.component.html',
  styleUrl: './license-administration.component.scss',
})
export class LicenseAdministrationComponent extends MatTableBaseComponent<UserListTableData> implements OnChanges {
  CY_LICENSEADMINISTRATION_BUTTON_MENU = CY_LICENSEADMINISTRATION_BUTTON_MENU;
  CY_LICENSEADMINISTRATION_BUTTON_DELETE = CY_LICENSEADMINISTRATION_BUTTON_DELETE;
  CY_LICENSEADMINISTRATION_BUTTON_DEACTIVATE = CY_LICENSEADMINISTRATION_BUTTON_DEACTIVATE;
  CY_LICENSEADMINISTRATION_BUTTON_ADDOWNERSHIP = CY_LICENSEADMINISTRATION_BUTTON_ADDOWNERSHIP;
  CY_LICENSEADMINISTRATION_BUTTON_REMOVEOWNERSHIP = CY_LICENSEADMINISTRATION_BUTTON_REMOVEOWNERSHIP;
  CY_LICENSEADMINISTRATION_SELECT_ROLE = CY_LICENSEADMINISTRATION_SELECT_ROLE;
  /**
   * Input property for the list of project users.
   */
  @Input({ required: true }) users!: ProjectUserDisplay[];

  /**
   * Input property for the number of available licenses in the project.
   */
  @Input({ required: true }) availableLicenses!: number;

  /**
   * Input property for the number of used licenses in the project.
   */
  @Input({ required: true }) usedLicenses!: number;

  /**
   * Input property for the filter string.
   */
  @Input() filter: string = '';

  /**
   * Output event emitter for deleting a user from the project.
   * Emits a `ProjectUserDisplay` object when a user is deleted.
   */
  @Output() deleteUser: EventEmitter<ProjectUserDisplay> = new EventEmitter<ProjectUserDisplay>();

  /**
   * Output event emitter for deactivating a user.
   * Emits a `ProjectUserDisplay` object when a user is deactivated.
   */
  @Output() deactivateUser: EventEmitter<ProjectUserDisplay> = new EventEmitter<ProjectUserDisplay>();

  /**
   * Output event emitter for changing the user data.
   */
  @Output() changeData: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Array of column names to be displayed in the table.
   *
   * @type {string[]}
   */
  displayedColumns: string[] = [
    'select',
    'name',
    'status',
    'userRole',
    'isOwner',
    'isPaidUser', // Höhere Rolle als Betrachter
    'entryDate', // project users - createTime
    'invitedBy', // project users - author
    'actions',
  ];

  /**
   * Array of options for user roles in the project.
   * Each option contains a value and a view value.
   *
   * @type {MatSelectOption[]}
   */
  userRoleOptions: MatSelectOption[] = [
    { value: 'member', viewValue: 'Mitglied' },
    { value: 'auditor', viewValue: 'Auditor' },
    { value: 'viewer', viewValue: 'Betrachter' },
  ];

  public constructor(
    public _permissionService: PermissionService,
    public _projectService: ProjectService,
    private _roleService: RoleService,
    private _firebaseService: FirebaseService,
    private _dialog: MatDialog,
    private _analyticsService: AnalyticsService,
    _userService: UserService,
    _snackbarService: SnackbarService,
    _cdr: ChangeDetectorRef
  ) {
    super(_userService, _snackbarService, _cdr);
  }

  /**
   * Lifecycle hook that is called when any data-bound property of a directive changes.
   * Applies the filter and updates the data source when the filter or users input properties change.
   *
   * @param {SimpleChanges} changes - The changes object containing the new and old values of the input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    const filterChange: SimpleChange = changes['filter'];
    const usersChange: SimpleChange = changes['users'];

    // Check if the filter has changed and it's not the first change.
    // If so, apply the new filter value.
    if (filterChange && !filterChange.firstChange) {
      this.applyFilter(changes['filter'].currentValue);
    }

    // Initialize the data source when the users input property changes.
    if (usersChange) {
      this.initDataSource();
    }
  }

  /**
   * Emits an event to delete a user from the project.
   *
   * @param {ProjectUserDisplay} user - The user to be deleted.
   */
  onDeleteUser(user: ProjectUserDisplay) {
    this.deleteUser.emit(user);
  }

  /**
   * Emits an event to deactivate a user.
   *
   * @param {ProjectUserDisplay} user - The user to be deactivated.
   */
  onDeactivateUser(user: ProjectUserDisplay) {
    this.deactivateUser.emit(user);
  }

  /**
   * Handles the change of a user's role in the project.
   * Opens a confirmation dialog before changing the role.
   * Displays an error message if the role change fails.
   *
   * @param {MatSelectChange} event - The event object containing the new role value.
   * @param {UserListTableData} row - The user data to change the role for.
   */
  onUserRoleChange(event: MatSelectChange, row: UserListTableData) {
    const role: string = event.value;
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: CY_LICENSEADMINISTRATION_SELECT_ROLE,
      value: role,
    });

    if (!row.authUserId) {
      this._snackbarService.showErrorMessage('Benutzer authUserId nicht gefunden');
      console.error('authUserId not found while trying to change user role');
      return;
    }
    this._dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        width: '360px',
        data: { dynamicContent: `Zum ${userRoles[role]} machen?` },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async takeAction => {
        if (!takeAction) {
          // reinitialize the data source to reset the user role if the user cancels the action
          this.initDataSource();
          return;
        }
        // Update the user's role in the project.
        await this._roleService
          .changeProjectRoleAssignmentRoles(this._projectService.project$.value!.id, row.authUserId!, role as RoleId)
          .catch(error => {
            console.error('Error changing user role:', error);
            this._snackbarService.showErrorMessage('Fehler beim Ändern der Benutzerrolle.');
          });

        this.changeData.emit();
      });
  }

  public changeOwnership(user: ProjectUserDisplay, makeOwner?: boolean) {
    this._dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        width: '360px',
        data: {
          dynamicContent: makeOwner ? 'Zum Eigentümer machen?' : 'Eigentümerschaft entziehen?',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async takeAction => {
        if (!takeAction) return;
        if (!user.roleAssignment) {
          this._snackbarService.showErrorMessage('Benutzer hat keine Rollenzuweisung');
          throw new Error('User has no role assignment');
        }
        const filteredRoles = user.roleAssignment.roles
          .map(role => role.id)
          .filter(o => {
            return o !== 'owner';
          });

        if (makeOwner) filteredRoles.push('owner');

        const roleAssignmentToSet: RoleAssignment = {
          id: user.roleAssignment.id,
          roles: filteredRoles.map(role => doc(this._firebaseService.firestore, 'roles', role)),
        };

        await lastValueFrom(
          this._roleService.setProjectRoleAssignment(this._projectService.project$.value!.id, user.authUserId!, roleAssignmentToSet)
        ).catch(error => {
          console.error('Error changing user role:', error);
          this._snackbarService.showErrorMessage('Fehler beim Ändern der Benutzerrolle.');
        });

        this.changeData.emit();
      });
  }

  /**
   * Checks if there is more than one owner in the project.
   *
   * @returns {boolean} True if there is more than one owner, false otherwise.
   */
  public get hasMoreThenOneOwner(): boolean {
    return this.users.filter(user => user.roles?.includes('owner')).length > 1;
  }

  /**
   * Initializes the data source for the user list table.
   * Maps the users array to update the data source with the new user data.
   */
  private initDataSource() {
    this.dataSource.data = this.users.map(user => {
      // Construct the user's name from firstName and lastName, or use email if both are missing.
      const name: string = user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.email;

      // Determine if the user is a paid user based on their roles.
      const isPaidUser: string = user.roles?.some(role => {
        return role === 'member' || role === 'auditor';
      })
        ? 'Ja'
        : 'Nein';

      // Extract user document data and convert createTime to a Date object.
      const userDocumentData: FirebaseDocumentData<ProjectUserDisplay> = user as FirebaseDocumentData<ProjectUserDisplay>;
      const entryDate: Date | null = userDocumentData.createTime ? timeStampToDate(userDocumentData.createTime) : null;
      const entryDateString: string = entryDate ? getLocaleDateString(entryDate, true) || '' : '';

      // Get the user's status, defaulting to 'active' if not set.
      const status: UserStatus = user.userStatus || 'active';

      // Filter out the 'owner' role from the user's roles.
      const userRoles = user.roles?.filter(o => o !== 'owner');

      // Return the updated user data with additional properties.
      return {
        ...user,
        name: name,
        isOwner: user.roles?.includes('owner') ? 'Ja' : 'Nein',
        isPaidUser: isPaidUser,
        entryDate: entryDateString,
        userRole: userRoles?.[0] || 'viewer',
        userStatus: status,
        status: userStatus[status],
        invitedBy: userDocumentData.author || '',
      } as UserListTableData;
    });
  }
}
