<div id="signup" class="flexbox overlay-background">
  <section id="signupContent" class="flex-grow-1 flexbox justify-center align-center direction-column">
    <div class="content-wrapper flexbox direction-column align-center">
      @if (isLoading$ | async) {
      <div class="loading-overlay">
        <mat-spinner diameter="50" />
      </div>
      } @else { @if( (appConfigSignUp$ | async)?.open) {
      <div class="header flexbox align-center direction-column">
        <h1 class="header-text primary">Willkommen bei eeule</h1>
        <div class="sub-header">Legen Sie jetzt los - Testen Sie kostenlos und unverbindlich die Vollversion</div>
      </div>
      @if(signUpMode) {
      <form class="sign-up-form flexbox direction-column" [formGroup]="signUpForm" (ngSubmit)="submit()">
        <div class="inputs flexbox direction-column">
          <mat-form-field class="mail w-100-percent no-background smaller-font">
            <!--          <mat-form-field class="mail w-100-percent no-background smaller-font" appearance="outline">-->
            <mat-label>E-Mail</mat-label>
            <mat-icon matPrefix>email</mat-icon>
            <input matInput formControlName="email" type="email" [errorStateMatcher]="errorStateMatcher" />
            @if (signUpForm.get('email')?.hasError('email')) {
            <mat-error>Bitte geben Sie eine gültige E-Mail Adresse an.</mat-error>
            }
          </mat-form-field>
          <mat-form-field class="password w-100-percent no-background smaller-font">
            <!--          <mat-form-field class="password w-100-percent no-background smaller-font" appearance="outline">-->
            <mat-label>Passwort</mat-label>
            <mat-icon matPrefix>lock</mat-icon>
            <input
              matInput
              formControlName="password"
              type="password"
              autocomplete="on"
              [errorStateMatcher]="errorStateMatcher"
              data-cy="input-signup-page-password"
            />
            @if (!signUpForm.controls.password.valid) {
            <mat-error
              >Mindestens 8 Zeichen, Kleinbuchstabe, Großbuchstabe, Zahl, Sonderzeichen {{ '(@, $, !, %, *, ?, &, +, -, §, €)' }}</mat-error
            >
            }
          </mat-form-field>
          @if (signUpForm.controls.password.valid) {
          <mat-form-field class="repeat-password w-100-percent no-background smaller-font">
            <mat-label>Passwort wiederholen</mat-label>
            <mat-icon matPrefix>lock</mat-icon>
            <input
              matInput
              formControlName="confirmPassword"
              type="password"
              autocomplete="on"
              [errorStateMatcher]="errorStateMatcher"
              data-cy="input-signup-page-confirmPassword"
            />
            @if (signUpForm.get('confirmPassword')?.hasError('mismatch')) {
            <mat-error>Die Passwörter stimmen nicht überein</mat-error>
            }
          </mat-form-field>
          }
          <mat-checkbox formControlName="noticeCheck" class="notice-check" color="primary">
            <span class="secondary-text">Ich stimme der </span>
            <a class="privacy accent" href="https://www.eeule.de/eeule-software-richtlinien/eeule-software-dse/" target="_blank"
              >Datenschutzerklärung</a
            >
            <span class="secondary-text">, den </span>
            <a class="terms-of-use accent" href="https://www.eeule.de/eeule-software-richtlinien/eeule-software-avv/" target="_blank"
              >AVV</a
            >
            <span class="secondary-text"> und den </span>
            <a class="conditions accent" href="https://www.eeule.de/eeule-software-richtlinien/eeule-software-agb/" target="_blank">AGB</a>
            <span class="secondary-text"> zu.</span>
          </mat-checkbox>
          <mat-checkbox formControlName="trackingCheck" class="notice-check" color="primary">
            <span class="secondary-text"
              >Für die technische Verbesserung unseres Produktes tracken wir Ihr Nutzerverhalten, sofern Sie hiermit einverstanden sind. Sie
              können den Einsatz auch verweigern oder später jederzeit widerrufen. Bitte entnehmen Sie unserer
              <a class="privacy accent" href="https://www.eeule.de/eeule-software-richtlinien/eeule-software-dse/" target="_blank"
                >Datenschutzerklärung</a
              >
              die entsprechenden Regelungen hierzu.</span
            >
          </mat-checkbox>
        </div>

        <button mat-flat-button color="primary" [disabled]="!signUpForm.valid || !signUpForm.controls.noticeCheck.value" class="rounded">
          <span class="button-label">REGISTRIEREN</span>
        </button>
      </form>
      } @else {
      <p class="email-sent primary">
        Wir haben Ihnen eine E-mail gesendet. Bitte verifizieren Sie zunächst Ihre E-Mail Adresse bevor Sie mit unserer Anwendung starten.
      </p>
      <button mat-flat-button color="primary" class="rounded" (click)="send()">
        <span class="button-label">Email-Verifizierungslink erneut senden</span>
      </button>
      } } @else if((appConfigSignUp$ | async) && !(appConfigSignUp$ | async)?.open) {
      <p>Es tut uns leid, die Registrierung ist aktuell geschlossen</p>
      } }
    </div>
    @if((appConfigSignIn$ | async)?.open) {
    <div class="to-login">
      <span>Haben Sie bereits ein Konto? </span>
      <a class="conditions accent" [routerLink]="'/login'">Anmelden</a>
    </div>
    }
  </section>

  <section id="signupImage"></section>
</div>
