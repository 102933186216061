<div id="auditSummary" class="flexbox align-center default-gap">
  <mat-card class="summary-card custom-card flex-grow-1 smaller-header full-width-header">
    <mat-card-header class="summary-card-header">
      <mat-card-title class="summary-card-title flexbox small-gap align-center justify-spaced">
        <div class="title">Szenarien</div>
        @if (scenariosLoading) {
        <mat-spinner diameter="15" />
        }
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100-percent flexbox direction-column justify-end overflow-hidden">
      @if(!scenariosLoading && (preCheckVsAuditScenarios$ | async); as preCheckVsAuditScenarios) {
      <eule-compare-values-visualization [compareValues]="preCheckVsAuditScenarios" />
      }
    </mat-card-content>
  </mat-card>

  <mat-card class="summary-card custom-card flex-grow-1 smaller-header full-width-header">
    <mat-card-header class="summary-card-header">
      <mat-card-title class="summary-card-title flexbox small-gap justify-spaced align-center">
        <div class="title">Dokumente</div>
        @if (documentsLoading) {
        <div class="loading-indicator">
          <mat-spinner diameter="15" />
        </div>
        }
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100-percent flexbox direction-column justify-end overflow-hidden">
      @if(!documentsLoading && (fileCountVsSize$ | async); as fileCountVsSize) {
      <eule-compare-values-visualization [compareValues]="fileCountVsSize" />
      }
    </mat-card-content>
  </mat-card>

  <mat-card class="summary-card custom-card flex-grow-1 smaller-header full-width-header">
    <mat-card-header class="summary-card-header">
      <mat-card-title class="summary-card-title flexbox small-gap align-center justify-spaced">
        <div class="title">Aufgaben</div>
        @if (!tasksCountVsDone) {
        <mat-spinner diameter="15" />
        }
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100-percent flexbox direction-column justify-end overflow-hidden">
      @if(tasksCountVsDone) {
      <eule-compare-values-visualization [compareValues]="tasksCountVsDone" />
      }
    </mat-card-content>
  </mat-card>

  <mat-card class="summary-card custom-card flex-grow-1 smaller-header full-width-header">
    <mat-card-header class="summary-card-header">
      <mat-card-title class="summary-card-title flexbox small-gap align-center justify-spaced">
        <div class="title">Benutzer</div>
        @if (usersLoading) {
        <mat-spinner diameter="15" />
        }
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100-percent flexbox direction-column justify-end overflow-hidden">
      @if(!usersLoading && (userCountVsViewerCount$ | async); as userCountVsViewerCount) {
      <eule-compare-values-visualization [compareValues]="userCountVsViewerCount" />
      }
    </mat-card-content>
  </mat-card>
</div>
