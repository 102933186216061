import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { UserService } from '../../../core/services/user.service';

export const CY_FEEDBACKDIALOG_BUTTON_CANCEL = 'feedback_dialog.button.cancel';
export const CY_FEEDBACKDIALOG_BUTTON_SEND = 'feedback_dialog.button.send';
interface FeedbackForm {
  email: FormControl<string | null>;
  creatorId: FormControl<string | null>;
  description: FormControl<string | null>;
  date: FormControl<number | null>;
}

export interface FeedbackDialogComponentConfig {}
export interface FeedbackDialogComponentResult {
  id?: string;
  email: string;
  description: string;
  date: number;
  creatorId: string;
}

@Component({
  selector: 'eule-feedback-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './feedback-dialog.component.html',
  styleUrl: './feedback-dialog.component.scss',
})
export class FeedbackDialogComponent {
  CY_FEEDBACKDIALOG_BUTTON_CANCEL = CY_FEEDBACKDIALOG_BUTTON_CANCEL;
  CY_FEEDBACKDIALOG_BUTTON_SEND = CY_FEEDBACKDIALOG_BUTTON_SEND;
  
  public constructor(
    private _analyticsService: AnalyticsService,
    private _dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private _formBuilder: FormBuilder,
    private _userService: UserService
  ) {}
  public feedbackForm: FormGroup<FeedbackForm> = this._formBuilder.group({
    email: this._formBuilder.control(this._userService.euleUser$.value!.email, Validators.required),
    creatorId: this._formBuilder.control(this._userService.euleUser$.value!.id, Validators.required),
    description: this._formBuilder.control('', Validators.required),
    date: this._formBuilder.control(new Date().valueOf()),
  });

  public closeDialog(save: boolean, label: string) {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label,
    });
    if (save) {
      this._dialogRef.close(this.feedbackForm.getRawValue());
    } else {
      this._dialogRef.close(null);
    }
  }
}
