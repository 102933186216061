<div id="newPasswordDialog" class="default-padding">
  <div class="container">
    <!-- Spalte 1 -->
    <div [formGroup]="newPasswordForm" class="col">
      <div class="primary-text">
        Passwortrichtlinien
        <ul>
          <li>Mindestens 8 Zeichen</li>
          <li>Kleinbuchstabe</li>
          <li>Großbuchstabe</li>
          <li>Zahl</li>
          <li>Sonderzeichen {{ '(@, $, !, %, *, ?, &, +, -, §, €)' }}</li>
        </ul>
      </div>

      <mat-form-field>
        <mat-label>Neues Passwort</mat-label>
        <input
          matInput
          #preCheckClp
          formControlName="newPassword"
          type="password"
          autocomplete="on"
          data-cy="input-new-passwort-dialog-newPassword"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Neues Passwort bestätigen</mat-label>
        <input
          matInput
          #preCheckClp
          formControlName="newPasswordRepeat"
          type="password"
          autocomplete="on"
          data-cy="input-new-passwort-dialog-newPasswordRepeat"
        />
        <p class="warn default-sub-title" *ngIf="newPasswordForm.controls['newPasswordRepeat'].errors">
          <span>Neue Passwörter nicht identisch</span>
        </p>
      </mat-form-field>
    </div>
  </div>
  <button mat-raised-button color="primary" [disabled]="newPasswordForm.invalid" (click)="save()">Speichern</button>
</div>
