import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, effect, inject, Signal, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AppConfigStripe, MixedUsageProfile, Pagination, Project, ProjectData, StripeProjectInfo } from '@eeule/eeule-shared';
import { Timestamp } from 'firebase/firestore';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { getUsageProfileEnumValue } from '../../../util/enum.helper';
import { BaseComponent } from '../../core/components/base/base.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { GeneralTitleComponent } from '../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../core/components/search-bar/search-bar.component';
import { CustomTooltipDirective } from '../../core/directives/custom-tooltip.directive';
import { TypeSafeMatCellDef } from '../../core/directives/TypeSafeMatCellDef';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';
import { AppConfigService } from '../../core/services/appConfig/app-config.service';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { IndicatorService } from '../../core/services/indicator.service';
import { PermissionService } from '../../core/services/permission.service';
import { ProjectService } from '../../core/services/project.service';
import { SnackbarService } from '../../core/services/snackbar.service';
import { UserService } from '../../core/services/user.service';
import { UsageProfileEnum } from '../../enums/UsageProfile.enum';
import { NewProjectDialogComponent } from '../components/new-project-dialog/new-project-dialog.component';
import {
  SubscriptionSelectionDialogComponent,
  SubscriptionSelectionDialogConfig,
} from '../components/subscription-selection-dialog/subscription-selection-dialog.component';
import { NewProjectHelperService } from '../services/new-project-helper.service';
import { ProjectListStore } from './project-list.store';

export type ProjectTableData = Project & {
  projectOwners: string[];
  usageProfileKeys: UsageProfileEnum[];
  currentUserCanDelete?: boolean;
  stripeInfo?: StripeProjectInfo;
};

@Component({
  selector: 'eule-project-list',
  standalone: true,
  imports: [
    CommonModule,
    CustomTooltipDirective,
    GeneralTitleComponent,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltip,
    SearchBarComponent,
    TypeSafeMatCellDef,
  ],
  providers: [ProjectListStore],
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
})
export class ProjectListComponent extends BaseComponent implements AfterViewInit {
  public store: ProjectListStore = inject(ProjectListStore);
  projects: Signal<ProjectTableData[]> = this.store.projects;

  public displayedColumns: string[] = [
    'name',
    'number',
    'systemType',
    'projectOwners',
    'date',
    'addressCity',
    'usageProfiles',
    'status',
    'product',
    'currentlyLicensedUsers',
    'actions',
  ];
  public dataSource: MatTableDataSource<ProjectTableData> = new MatTableDataSource<ProjectTableData>();
  public filterValue: string = '';
  public deletingInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public appConfigStripe$ = this._appConfigService.getLiveAppConfigStripe().pipe(
    catchError(error => {
      const _errorLog = {
        message: 'Error loading stripeConfig',
        data: {
          originalError: error,
        },
      };
      this._snackbarService.showErrorMessage(`Fehler beim Laden der StripeConfig ${error}`, 5000, _errorLog);
      return throwError(() => _errorLog);
    }),
    takeUntil(this.stop$),
    take(1),
    shareReplay(1)
  );

  protected readonly getUsageProfileEnumValue = getUsageProfileEnumValue;

  private _refresh$: BehaviorSubject<null> = new BehaviorSubject(null);
  private _authUserId: string | undefined = this._authService.getAuth().currentUser?.uid;

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  constructor(
    public permissionService: PermissionService,
    private _projectService: ProjectService,
    public userService: UserService,
    private _analyticsService: AnalyticsService,
    private _router: Router,
    private _newProjectDialog: MatDialog,
    private _subscriptionSelectionDialog: MatDialog,
    private _indicatorService: IndicatorService,
    private _snackbarService: SnackbarService,
    private _newProjectHelperService: NewProjectHelperService,
    private _authService: AuthService,
    private _confirmDialog: MatDialog,
    private _appConfigService: AppConfigService
  ) {
    super();
    this._analyticsService.sendPageView('project-list-page');
    effect(() => {
      this.dataSource.data = this.projects();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // Benutzerdefinierte Sortierlogik
    this.dataSource.sortingDataAccessor = (item: ProjectTableData, property: string): number | string => {
      switch (property) {
        case 'status':
          return item.stripeInfo?.status || 0;
        case 'product':
          return item.stripeInfo?.product || 0;
        case 'currentlyLicensedUsers':
          return item.stripeInfo?.currentlyLicensedUsers || 0;
        default:
          return (item[property as keyof ProjectTableData] as string | number) || ''; // Fallback für andere Felder
      }
    };
  }

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }

  public newProject(): void {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: 'project-list_newProject',
    });
    const newProjectDialogRef = this._newProjectDialog.open<NewProjectDialogComponent, Project, Project | undefined>(
      NewProjectDialogComponent,
      {
        width: '40vw',
        maxWidth: '40vw',
        height: '80vh',
      }
    );

    newProjectDialogRef
      .afterClosed()
      .pipe(
        switchMap((newProjectDialogResult: Project | undefined) => combineLatest([of(newProjectDialogResult), this.appConfigStripe$])),
        switchMap(([newProjectDialogResult, stripe]: [Project | undefined, AppConfigStripe]) => {
          if (!newProjectDialogResult) {
            return EMPTY; // normaler geplanter Abbruch, wenn der Dialog abgebrochen bzw. ohne Ergebnis geschlossen wurde
          }
          if (!newProjectDialogResult?.dgnbSystem) {
            throw new Error('Project DGNB System not set');
          }
          if (!newProjectDialogResult?.id) {
            throw new Error('Project ID not set');
          }
          if (newProjectDialogResult?.bgfBigger5000 === newProjectDialogResult?.bgfSmaller5000) {
            throw new Error('bgfBigger5000 and bgfSmaller5000 have the same value. One must be true, the other false');
          }
          if (newProjectDialogResult?.withDeconstruction === newProjectDialogResult?.withoutDeconstruction) {
            throw new Error('withDeconstruction and withoutDeconstruction have the same value. One must be true, the other false');
          }

          if (newProjectDialogResult && this._authUserId) {
            this.isLoading$.next(true);
            const projectOwnerId: string = crypto.randomUUID();
            const _project: Project = {
              ...newProjectDialogResult,
              buildingDescriptionGroups: this._newProjectHelperService.getBuildingDescriptionGroups(),
              usageProfiles: newProjectDialogResult.usageProfiles.map((profile: MixedUsageProfile) =>
                this._newProjectHelperService.getUsageProfileWithDescriptionGroup(profile)
              ),
              certificationDescriptionGroups: this._newProjectHelperService.getCertificationDescriptionGroups(),
              imagesDescriptionGroups: this._newProjectHelperService.getImagesDescriptionGroups(),
              projectOwner: projectOwnerId,
              leistungsPhasen: new Array(9).fill(null),
              qng: false,
              euTaxonomy: false,
            };
            // Only if Stripe is active, set the softDeleted flag.
            if (stripe.active) {
              _project.softDeleted = Timestamp.fromDate(new Date());
            }

            if (!_project.usageProfiles.length) {
              const _errorLog = {
                message: 'Empty usageProfiles',
                data: { project: _project },
              };
              this._snackbarService.showErrorMessage('Nutzungsprofilerstellung gescheitert', 5000, _errorLog);
              return throwError(() => _errorLog);
            }
            return combineLatest([of(_project), this._projectService.createProject(_project), this.appConfigStripe$]);
          }
          return EMPTY;
        }),
        switchMap(([proj, ready, config]) =>
          this._indicatorService
            .cloud_copyDgnbSystemToProjectPreCheckWaitForError(
              `dgnbSystems/${proj.dgnbSystem}`,
              `projects/${proj.id}/preCheckScenarios/${crypto.randomUUID()}`
            )
            .pipe(map((): [Project, void, AppConfigStripe] => [proj, ready, config]))
        ),
        // switchMap(([proj]: [Project, void, AppConfigStripe]) => {
        // FIXME: nötige rules für diesen Vorgang sind an dieser Stelle noch nicht vorhanden
        //  -> Funktion auskommentiert -> Funktionalität in cloud function "onCallStripCheckoutSessionCompleted" implementiert
        // return combineLatest([of(proj), this.projectService.addUserToProject(proj.id, this._authUserId!), this.appConfigStripe$]);
        // }),
        switchMap(([proj, , stripe]: [Project, void, AppConfigStripe]) =>
          combineLatest([
            of(proj),
            of(stripe),
            from(
              this._appConfigService.getAppConfigStripeSystemUsageProfileConfiguration(proj.dgnbSystem, proj.usageProfiles[0].usageProfile)
            ).pipe(
              catchError(error => {
                const _errorLog = {
                  message: 'Empty usageProfiles',
                  data: { project: proj, originalError: error },
                };
                this._snackbarService.showErrorMessage(
                  `Fehler beim Laden der Stripe-Nutzungsprofil-Konfiguration in Projekt ${proj.id} ${error}`,
                  5000,
                  _errorLog
                );
                return throwError(() => _errorLog);
              })
            ),
          ])
        ),
        switchMap(([proj, stripeConfig, stripeSystemConfiguration]) => {
          if (!stripeSystemConfiguration) {
            const _errorLog = {
              message: 'Stripe System Configuration is missing',
              data: { project: proj, stripeConfig: stripeConfig, stripeSystemConfiguration: stripeSystemConfiguration },
            };
            this._snackbarService.showErrorMessage(
              `Fehler beim Laden der Stripe-System-Konfiguration in Projekt ${proj.id}`,
              5000,
              _errorLog
            );
            return throwError(() => _errorLog);
          }
          if (stripeConfig.active && stripeSystemConfiguration.pricingTableId) {
            return this._subscriptionSelectionDialog
              .open<SubscriptionSelectionDialogComponent, SubscriptionSelectionDialogConfig, unknown | undefined>(
                SubscriptionSelectionDialogComponent,
                {
                  width: '80vw',
                  maxWidth: '80vw',
                  height: '90vh',
                  disableClose: true,
                  data: {
                    clientReferenceId: `${this.userService.euleUser$.value!.id}_${proj.id}`,
                    email: this.userService.euleUser$.value!.email,
                    projectId: proj.id,
                    pricingTableId: stripeSystemConfiguration.pricingTableId,
                  },
                }
              )
              .afterClosed()
              .pipe(
                tap(() => this.isLoading$.next(false)),
                map(() => [proj] as [Project]) // explizit typisieren
              );
          } else {
            const _errorLog = { message: 'Error in Project Creation Process' };
            this._snackbarService.showErrorMessage('Fehler in Projekterstellung', 5000, _errorLog);
            return of([proj] as [Project]);
          }
        }),
        catchError(error => {
          this.isLoading$.next(false);
          const _errorLog = { message: 'Unknown Error' };
          this._snackbarService.showErrorMessage(`Unbekannter Fehler ${error}`, 5000, _errorLog);
          throw new Error('Unknown Error:', error);
        })
      )
      .subscribe();
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue;
    if (this.dataSource) {
      this.dataSource.filter = _filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      } else {
        new Error('No DataSource for Table');
      }
    }
  }

  public clickProjekt(project: ProjectData) {
    this._router.navigate(['/intern/project', project.id]).catch(error => {
      const _errorLog = {
        message: 'Error navigating to project',
        data: { project: project, originalError: error },
      };
      this._snackbarService.showErrorMessage(`Konnte nicht in Projekt navigieren`, 5000, _errorLog);
      return throwError(() => _errorLog);
    });
  }

  public onDelete(projectId: string, event: Event) {
    event.stopPropagation();
    this._confirmDialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        width: '360px',
        data: { dynamicContent: 'Projekt löschen' },
      })
      .afterClosed()
      .pipe(
        take(1),
        switchMap(takeAction => {
          if (!takeAction) return EMPTY;
          this.deletingInProgress$.next(true);
          return from(this._projectService.cloudFunctionSoftDeleteProject(projectId));
        }),
        tap(() => {
          this.deletingInProgress$.next(false);
          this._refresh$.next(null);
        }),
        catchError(error => {
          this.deletingInProgress$.next(false);
          const _errorLog = {
            message: 'Error deleting Project',
            data: {
              projectId: projectId,
              originalError: error,
            },
          };
          this._snackbarService.showErrorMessage(`Fehler beim Löschen des Projektes ${projectId}`, 5000, _errorLog);
          return throwError(() => _errorLog);
        })
      )
      .subscribe();
  }
}
