import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { UserStatus } from '@eeule/eeule-shared';
import { userRoles } from '../../../../util/translation.helper';
import { MatTableBaseComponent } from '../../../core/components/mat-table-base/mat-table-base.component';
import { TypeSafeMatCellDef } from '../../../core/directives/TypeSafeMatCellDef';
import { PermissionService } from '../../../core/services/permission.service';
import { ProjectService } from '../../../core/services/project.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { ProjectUserDisplay, UserService } from '../../../core/services/user.service';
import { UserListTableData } from '../../home/users-list-page/users-list-page.component';

export const CY_USERSLIST_BUTTON_MENU = 'users_list.button.menu';
export const CY_USERSLIST_BUTTON_DELETE = 'users_list.button.delete';
export const CY_USERSLIST_BUTTON_DISABLE = 'users_list.button.disable';

@Component({
  selector: 'eule-users-list',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
    TypeSafeMatCellDef,
    MatProgressSpinnerModule,
    MatSortModule,
    MatCardModule,
    MatCheckbox,
    MatIconButton,
    MatMenuModule,
  ],
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss',
})
export class UsersListComponent extends MatTableBaseComponent<UserListTableData> implements OnChanges {
  CY_USERSLIST_BUTTON_MENU = CY_USERSLIST_BUTTON_MENU;
  CY_USERSLIST_BUTTON_DELETE = CY_USERSLIST_BUTTON_DELETE;
  CY_USERSLIST_BUTTON_DISABLE = CY_USERSLIST_BUTTON_DISABLE;
  /**
   * Input property for the list of project users.
   * This property is required.
   *
   * @type {ProjectUserDisplay[]}
   */
  @Input({ required: true }) users!: ProjectUserDisplay[];

  /**
   * Input property for the filter string.
   */
  @Input() filter: string = '';

  /**
   * Output event emitter for deleting a user from the project.
   * Emits a `ProjectUserDisplay` object when a user is deleted.
   */
  @Output() deleteUser: EventEmitter<ProjectUserDisplay> = new EventEmitter<ProjectUserDisplay>();

  /**
   * Output event emitter for deactivating a user.
   * Emits a `ProjectUserDisplay` object when a user is deactivated.
   */
  @Output() deactivateUser: EventEmitter<ProjectUserDisplay> = new EventEmitter<ProjectUserDisplay>();

  /**
   * Array of column names to be displayed in the table.
   */
  public displayedColumns: string[] = ['select', 'name', 'title', 'company', 'phone', 'email', 'userRole', 'actions'];

  /**
   * Constructor for the UsersListComponent.
   *
   * @param {PermissionService} _permissionService - The permission service.
   * @param {ProjectService} _projectService - The project service.
   * @param {UserService} _userService - The user service.
   * @param {SnackbarService} _snackbarService - The snackbar service.
   * @param {ChangeDetectorRef} _cdr - The change detector reference.
   */
  public constructor(
    public _permissionService: PermissionService,
    public _projectService: ProjectService,
    _userService: UserService,
    _snackbarService: SnackbarService,
    _cdr: ChangeDetectorRef
  ) {
    super(_userService, _snackbarService, _cdr);
  }

  /**
   * Lifecycle hook that is called when any data-bound property of a directive changes.
   * Applies the filter and updates the data source when the filter or users input properties change.
   *
   * @param {SimpleChanges} changes - The changes object containing the new and old values of the input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    const filterChange: SimpleChange = changes['filter'];
    const usersChange: SimpleChange = changes['users'];

    if (filterChange && !filterChange.firstChange) {
      this.applyFilter(changes['filter'].currentValue);
    }

    if (usersChange) {
      this.dataSource.data = this.users.map(user => {
        const name: string = user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.email;

        const userRole: string = user.roles?.filter(o => o !== 'owner')[0] || 'viewer';

        const status: UserStatus = user.userStatus || 'active';

        return {
          ...user,
          userRole: userRoles[userRole],
          name: name,
          userStatus: status,
        } as UserListTableData;
      });
    }
  }

  /**
   * Emits an event to delete a user from the project.
   *
   * @param {ProjectUserDisplay} user - The user to be deleted.
   */
  onDeleteUser(user: ProjectUserDisplay) {
    this.deleteUser.emit(user);
  }

  /**
   * Emits an event to deactivate a user.
   *
   * @param {ProjectUserDisplay} user - The user to be deactivated.
   */
  onDeactivateUser(user: ProjectUserDisplay) {
    this.deactivateUser.emit(user);
  }
}
