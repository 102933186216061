import { Routes } from '@angular/router';
import { _authGuard } from './core/guards/auth-guard-christian/auth.guard';
import { canDeactivateGuard } from './core/guards/auth-guard-christian/canDeactivate.guard';
import { isValidIframeAppGuard } from './core/guards/is-valid-iframe-app/is-valid-iframe-app.guard';
import { permissionGuard } from './core/guards/permission/permission.guard';
import { _loadProjectGuard } from './core/guards/project-loader/project-loader.guard';
import { redirectGuard } from './core/guards/redirect-guard/redirect.guard';
import { NavigationComponent } from './core/navigation/navigation.component';
import { setAuditDependenciesResolver } from './core/resolvers/set-audit-dependencies.resolver';
import { FeedbackPageComponent } from './global/feedback/feedback-page.component';
import { LogoutComponent } from './global/logout/logout.component';
import { QuestionsAndAnswersPageComponent } from './global/questions-and-answers-page/questions-and-answers-page.component';
import { SettingsComponent } from './global/settings/settings.component';
import { LoginComponent } from './login/login-page/login.component';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { ProfilePageComponent } from './profile/profile-page/profile-page.component';
import { AppDetailPageComponent } from './projects/apps/components/app-detail-page/app-detail-page.component';
import { AppDashboardComponent } from './projects/apps/components/dashboard/app-dashboard.component';
import { AuditDocumentsPageComponent } from './projects/audit/audit-documents-page/audit-documents-page.component';
import { AuditStartPageComponent } from './projects/audit/audit-start-page/audit-start-page.component';
import { AuditTasksPageComponent } from './projects/audit/audit-tasks-page/audit-tasks-page.component';
import { HomeComponent } from './projects/home/home.component';
import { ProjectInfoPageComponent } from './projects/home/project-info-page/project-info-page/project-info-page.component';
import { UsersListPageComponent } from './projects/home/users-list-page/users-list-page.component';
import { ProjectListComponent } from './projects/project-list/project-list.component';
import { SubscriptionPageComponent } from './projects/settings/subscription-page/subscription-page.component';
import { ToolComponent } from './projects/tool/tool.component';
import { SignupPageComponent } from './signup/signup-page/signup-page.component';
import { VerifyPageComponent } from './verify/verify-page/verify-page.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // default route while enterprise does not exist
  {
    path: 'landing',
    redirectTo: 'login',
  },
  {
    path: 'login',
    canActivate: [redirectGuard],
    component: LoginComponent,
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'signup', component: SignupPageComponent },
  { path: 'verify', component: VerifyPageComponent },
  {
    path: 'intern',
    component: NavigationComponent,
    canActivate: [_authGuard],
    children: [
      {
        path: 'feedback',
        component: FeedbackPageComponent,
      },
      {
        path: 'qa',
        component: QuestionsAndAnswersPageComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },

      {
        path: 'projects',
        component: ProjectListComponent,
      },
      {
        path: 'project/:id',
        canActivate: [_loadProjectGuard], // loads all necessary Data when a project page is browsed (could also be a Resolver)
        children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          {
            path: 'home',
            component: HomeComponent,
          },
          {
            path: 'home',
            children: [
              {
                path: 'start',
                component: HomeComponent,
              },
              {
                path: 'project-info',
                component: ProjectInfoPageComponent,
                canDeactivate: [canDeactivateGuard],
                canActivate: [permissionGuard],
                data: { projectRights: ['project_update'] },
              },
              { path: 'company', component: HomeComponent },
              {
                path: 'users',
                component: UsersListPageComponent,
                canActivate: [permissionGuard],
                data: { projectRights: ['project_read_users'] },
              },
              { path: 'digital-twin', component: HomeComponent },
              { path: 'leistungsphasen', component: HomeComponent },
              { path: '**', component: AuditStartPageComponent },
            ],
          },
          {
            path: 'audit',
            resolve: { dep: setAuditDependenciesResolver },
            children: [
              { path: 'start', component: AuditStartPageComponent },
              {
                path: 'tasks',
                component: AuditTasksPageComponent,
              },
              {
                path: 'documents',
                component: AuditDocumentsPageComponent,
                canActivate: [permissionGuard],
                data: { projectRights: ['project_read_documents', 'project_read_documents_own'], needAllRights: false },
              },
              {
                path: 'pre-check',
                canActivate: [permissionGuard],
                data: { projectRights: ['project_read_pre_check_scenarios'] },
                loadChildren: () => import('./projects/audit/scenario-subjects.routes').then(mod => mod.preCheckSubjectsRoutes),
              },
              {
                path: 'audit-details',
                canActivate: [permissionGuard],
                data: { projectRights: ['project_read_audit_scenarios'] },
                loadChildren: () => import('./projects/audit/scenario-subjects.routes').then(mod => mod.auditSubjectsRoutes),
              },
              { path: '**', component: AuditStartPageComponent },
            ],
          },
          {
            path: 'apps',
            canActivate: [permissionGuard],
            data: { projectRights: ['project_read'] },
            children: [
              { path: 'dashboard', component: AppDashboardComponent },
              {
                path: ':id',
                canActivate: [isValidIframeAppGuard],
                component: AppDetailPageComponent,
              },
              { path: '**', component: AppDashboardComponent },
            ],
          },
          {
            path: 'settings',
            children: [
              { path: 'subscription', component: SubscriptionPageComponent },
              { path: '**', component: SubscriptionPageComponent },
            ],
          },
          { path: 'tool', component: ToolComponent },
        ],
      },
      {
        path: 'profile',
        component: ProfilePageComponent,
      },
      { path: 'no-permission', component: NoPermissionsComponent },
      { path: '**', component: ProjectListComponent },
    ],
  },
];
