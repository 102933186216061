<ng-container [formGroup]="descriptionGroup">
  <div class="row tab-content">
    <h2>{{ title === 'mainUsage' ? 'Hauptnutzung' : title === 'subordinateUsage' ? 'Untergeordnete Nutzung' : 'Nebennutzung' }}</h2>
  </div>

  <div class="row">
    <div class="col padding-right">
      @for (profileType of projectService.usageProfiles$ | async; track profileType) { @if (profileType.id === usageProfileType!.value) {
      <div style="margin-bottom: 2px">{{ usageProfileEnum[profileType.name] }}</div>
      } }
      <ng-container formArrayName="descriptions">
        @for (description of descriptions.controls; track description) {
        <ng-container [formGroup]="getDescriptionGroupAt($index)">
          <quill-editor
            class="w-100-percent filled-text-field-background small-radius flat-bottom"
            style="height: 180px; margin-bottom: 4rem"
            formControlName="description"
            [placeholder]="getDescriptionTitleAt($index)"
          >
            <div quill-editor-toolbar>
              <eule-custom-quill-editor-toolbar-set
                toolbarSet="custom"
                [allConfigPropsActivated]="true"
                [toolbarConfig]="{
              hasHeadingPicker: false,
              hasImagePicker: false,
            }"
              />
            </div>
          </quill-editor>
        </ng-container>
        }
      </ng-container>
    </div>
    <div class="col padding-right">
      <h3>Erforderliche Nachweise</h3>
      <ng-container formArrayName="attestations">
        @for (attestation of attestations.controls; track attestation) {
        <ng-container [formGroup]="getAttestationGroupAt($index)">
          <mat-checkbox formControlName="checked" color="primary">
            <span class="secondary-text">{{ getAttestationDescriptionAt($index) }}</span>
          </mat-checkbox>
        </ng-container>
        }
      </ng-container>

      <div class="attachments flexbox direction-column">
        <div class="label default-border-color primary-text flexbox small-gap align-center">
          <div class="attachments-title">Anhänge</div>
          <div
            [euleTooltip]="
              _permissionService.hasRights('project_create_document')
                ? 'Anhang zu Gruppe hinzufügen'
                : 'Sie besitzen nicht die benötigten Rechte,</br> um Anhänge hinzuzufügen.'
            "
            [htmlTooltipContent]="true"
            [tooltipPositionOffsetY]="6"
          >
            <button
              mat-mini-fab
              color="accent"
              class="no-shadow smaller-button"
              [disabled]="!_permissionService.hasRights('project_create_document')"
              [attr.data-cy]="CY_USAGEPROFILESGROUP_BUTTON_ADDATTACHMENT"
              (click)="onOpenAddAttachmentDialog()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
          @for (document of groupDocuments$ | async; track document) {
          <eule-document-tile [documentId]="document.id"></eule-document-tile>
          }
        </div>
      </div>
    </div>
  </div>
</ng-container>
