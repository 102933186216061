import { Pipe, PipeTransform } from '@angular/core';

/**
 * Determines if a role option should be disabled based on the current user's role and the number of used licenses.
 *
 * @export
 * @class RoleOptionDisabledPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'roleOptionDisabled',
  standalone: true,
})
export class RoleOptionDisabledPipe implements PipeTransform {
  transform(roleOption: string, currentUserRole: string, usedLicenses: number, availableLicenses: number): boolean {
    return roleOption !== 'viewer' && currentUserRole === 'viewer' && usedLicenses >= availableLicenses;
  }
}
