import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Project } from '@eeule/eeule-shared';
import { QuillEditorComponent } from 'ngx-quill';
import { BehaviorSubject } from 'rxjs';
import { CustomQuillEditorToolbarSetComponent } from '../../../../core/components/custom-quill-editor-toolbar-set/custom-quill-editor-toolbar-set.component';
import { CommaDecimalInputComponent } from '../../../../core/components/decimal-comma/decimal-comma.component';
import {
  UploadCroppedImageDialogComponent,
  UploadCroppedImageDialogComponentConfig,
} from '../../../../core/components/upload-cropped-image-dialog/upload-cropped-image-dialog.component';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../../core/services/project.service';
import { StorageService } from '../../../../core/services/storage.service';
import { OwnerTypeEnum } from '../../../../enums/OwnerType.enum';
import { GeneralFormGroup } from '../project-info-page/project-info-page.component';

@Component({
  selector: 'eule-tab-general',
  standalone: true,
  imports: [
    CommonModule,
    CommaDecimalInputComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
    QuillEditorComponent,
    CustomQuillEditorToolbarSetComponent,
  ],
  templateUrl: './tab-general.component.html',
  styleUrl: './tab-general.component.scss',
})
export class TabGeneralComponent implements OnInit {
  @Input({ required: true }) generalFormGroup!: FormGroup<GeneralFormGroup>;
  @Input({ required: true }) projectImage$!: BehaviorSubject<string | null | undefined>;

  public ownerTypeEnum: typeof OwnerTypeEnum = OwnerTypeEnum;

  public constructor(
    private _analyticsService: AnalyticsService,
    public projectService: ProjectService,
    private _storageService: StorageService,
    private _uploadCroppedImageDialog: MatDialog
  ) {
    this._analyticsService.sendPageView('tab-general-component');
  }

  ngOnInit() {
    this.projectService.project$.subscribe((project: Project | null) => {
      if (project) {
        this.projectService.projectUser$.pipe().subscribe(user => {
          if (user) {
            project.projectImagePath && this._loadProjectImage(project.projectImagePath);
          }
        });
      }
    });
  }

  public editProjectImage() {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: 'tab-general_button-edit-project-image',
    });
    const dialogRef = this._uploadCroppedImageDialog.open<
      UploadCroppedImageDialogComponent,
      UploadCroppedImageDialogComponentConfig,
      string | undefined
    >(UploadCroppedImageDialogComponent, {
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      data: {
        uploadPath: `projects/${this.projectService.project$.value!.id}/projectImage/${new Date().valueOf()}`,
        cropperMinWidth: 300,
        cropperMaxWidth: 1000,
        roundCropper: false,
        aspectRatio: 1.4,
        maintainAspectRatio: true,
      },
    });

    dialogRef.afterClosed().subscribe((resultPath: string | undefined) => {
      if (resultPath) {
        this.generalFormGroup!.get('projectImagePath')!.patchValue(resultPath);
        this.generalFormGroup!.get('projectImagePath')!.markAsDirty();
        this._loadProjectImage(resultPath);
      }
    });
  }

  private _loadProjectImage(path: string) {
    if (path) {
      this._storageService.downloadImage(path).subscribe(img => this.projectImage$!.next(img));
    }
  }
}
