import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppConfigSignUpSignIn, AppConfigStripe, AppConfigStripeSystemConfiguration } from '@eeule/eeule-shared';
import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { Observable } from 'rxjs';
import { FirebaseService } from '../firebase.service';

export interface AppConfigStripeSystemUsageProfileConfiguration {
  customerPortalId: string;
  pricingTableId: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(private _firebaseService: FirebaseService) {}

  readonly appConfigSignIn: Signal<AppConfigSignUpSignIn | null> = toSignal(this.getLiveAppConfigSignIn(), { initialValue: null });
  readonly appConfigSignUp: Signal<AppConfigSignUpSignIn | null> = toSignal(this.getLiveAppConfigSignUp(), { initialValue: null });

  public getLiveAppConfigSignUp(): Observable<AppConfigSignUpSignIn> {
    const docRef = doc(this._firebaseService.firestore, `appConfig/signUp`);
    return new Observable(observer => {
      return onSnapshot(
        docRef,
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => observer.next(snapshot.data() as AppConfigSignUpSignIn),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public getLiveAppConfigStripe(): Observable<AppConfigStripe> {
    const docRef = doc(this._firebaseService.firestore, `appConfig/stripe`);
    return new Observable(observer => {
      return onSnapshot(
        docRef,
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => observer.next(snapshot.data() as AppConfigStripe),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public async getAppConfigStripeSystemConfiguration(systemId: string): Promise<AppConfigStripeSystemConfiguration | null> {
    const systemConfigurationsRef = collection(this._firebaseService.firestore, 'appConfig/stripe/systemConfiguration');
    const q = query(systemConfigurationsRef, where('systemId', '==', systemId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return doc.data() as AppConfigStripeSystemConfiguration;
    } else {
      return null;
    }
  }

  public async getAppConfigStripeSystemUsageProfileConfiguration(
    systemId: string,
    usageProfileId: string
  ): Promise<AppConfigStripeSystemUsageProfileConfiguration | null> {
    const systemConfigurationsRef = doc(
      this._firebaseService.firestore,
      `appConfig/stripe/systemConfiguration/${systemId}/mainUsageProfileConfig/${usageProfileId}`
    );
    const docSnapshot = await getDoc(systemConfigurationsRef);

    if (docSnapshot.exists()) {
      return docSnapshot.data() as AppConfigStripeSystemUsageProfileConfiguration;
    } else {
      return null;
    }
  }

  public getLiveAppConfigSignIn(): Observable<AppConfigSignUpSignIn> {
    const docRef = doc(this._firebaseService.firestore, `appConfig/signIn`);
    return new Observable(observer => {
      return onSnapshot(
        docRef,
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => observer.next(snapshot.data() as AppConfigSignUpSignIn),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public isSignUpSignInAllowed(email: string, config: AppConfigSignUpSignIn): boolean {
    // if generally closed, return false
    if (!config.open) return false;

    const mailDomain = '@' + email.split('@')[1];
    // if open but has whitelist and mail is on whitelist, return true
    if (config.whiteList.length > 0 && (config.whiteList.includes(email) || config.whiteList.includes(mailDomain))) {
      return true;
    }
    // if was not previously on whitelist, but there is a whitelist, return false
    else if (config.whiteList.length > 0) return false;
    // if there was no whitelist but there is a blacklist and email is included in blacklist, return false.
    // this means also a whitelist is superior over a blacklist
    if (config.blackList.length > 0 && (config.blackList.includes(email) || config.blackList.includes(mailDomain))) {
      return false;
    }
    // if there was no whitelist or mail is not on blacklist, return true
    return true;
  }
}
