import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { User } from '@eeule/eeule-shared';
import { takeUntil } from 'rxjs';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { AuthService } from '../../services/auth-christian/auth.service';
import { ThemeService } from '../../services/theme.service';
import { UserService } from '../../services/user.service';
import { BaseComponent } from '../base/base.component';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';

export const CY_NAVIGATIONPROFILEBAR_BUTTON_PROFILE = 'navigation_profile_bar.button.profile';
export const CY_NAVIGATIONPROFILEBAR_BUTTON_LOGOUT = 'navigation_profile_bar.button.logout';
export const CY_NAVIGATIONPROFILEBAR_BUTTON_CONTACT = 'navigation_profile_bar.button.contact';
export const CY_NAVIGATIONPROFILEBAR_TOGGLE_DARKMODE = 'navigation_profile_bar.toggle.darkmode';
export const CY_NAVIGATIONPROFILEBAR_COMPONENT_PROFILEPICTURE = 'navigation_profile_bar.component.profile_picture';

@Component({
  selector: 'eule-navigation-profile-bar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSlideToggleModule,
    ProfilePictureComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './navigation-profile-bar.component.html',
  styleUrl: './navigation-profile-bar.component.scss',
})
export class NavigationProfileBarComponent extends BaseComponent implements OnInit {
  CY_NAVIGATIONPROFILEBAR_BUTTON_PROFILE = CY_NAVIGATIONPROFILEBAR_BUTTON_PROFILE;
  CY_NAVIGATIONPROFILEBAR_BUTTON_LOGOUT = CY_NAVIGATIONPROFILEBAR_BUTTON_LOGOUT;
  CY_NAVIGATIONPROFILEBAR_BUTTON_CONTACT = CY_NAVIGATIONPROFILEBAR_BUTTON_CONTACT;
  CY_NAVIGATIONPROFILEBAR_TOGGLE_DARKMODE = CY_NAVIGATIONPROFILEBAR_TOGGLE_DARKMODE;
  CY_NAVIGATIONPROFILEBAR_COMPONENT_PROFILEPICTURE = CY_NAVIGATIONPROFILEBAR_COMPONENT_PROFILEPICTURE;

  public toggleControl = new FormControl(false);

  constructor(
    private _router: Router,
    public userService: UserService,
    private _themeService: ThemeService,
    private _analyticsService: AnalyticsService,
    private _authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe(darkMode => {
      this._analyticsService.sendEvent('click', {
        category: 'toggle',
        label: CY_NAVIGATIONPROFILEBAR_TOGGLE_DARKMODE,
        value: darkMode,
      });
      this._themeService.setTheme(darkMode ? 'dark-theme' : 'default-theme');
      this.userService.updateUser(this.userService.euleUser$.value!.id, { darkMode: !!darkMode });
    });
    this.userService.euleUser$.pipe(takeUntil(this.stop$)).subscribe((user: User | null) => {
      if (!user) return;
      this.toggleControl.setValue(!!user!.darkMode);
    });
  }

  public navigate(path: string) {
    this._router.navigate([`/intern/${path}`]);
  }

  public openContactPageInNewTab() {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: CY_NAVIGATIONPROFILEBAR_BUTTON_CONTACT,
    });
    window.open('https://www.eeule.de/#kontakt', '_blank');
  }

  public logout() {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: CY_NAVIGATIONPROFILEBAR_BUTTON_LOGOUT,
    });
    this._authService.logout();
  }

  public getUserName(): string {
    return this._authService.getAuth().currentUser ? this._authService.getAuth().currentUser!.email! : '';
  }

  public clickProfilePicture() {
    this._analyticsService.sendEvent('click', {
      category: 'component',
      label: CY_NAVIGATIONPROFILEBAR_COMPONENT_PROFILEPICTURE,
    });
  }
}
