<div class="login flexbox direction-column centered smaller-gap" [ngClass]="{ handset: (isHandset$ | async) }">
  @if (store.isLoading()) {
  <div class="loading-overlay primary-background">
    <mat-spinner diameter="100" />
  </div>
  } @else {@if(store.signInOpen()) {
  <mat-card class="login-card custom-card semi-round shadow-1">
    <section class="header flexbox direction-column">
      <div class="eeule-logo flexbox centered direction-column">
        <img alt="logo" class="logo" src="assets/images/eeule_logo_100x96.png" width="100" height="96" />
        <img alt="logo-label" class="logo-label" src="assets/images/eeule_schrift_logo_webview_120x38.png" width="120" height="38" />
      </div>
      <div class="sub-header primary">In Ihrem Konto anmelden</div>
    </section>
    <form class="login-form flexbox direction-column" [formGroup]="loginForm" (ngSubmit)="submit()">
      <div class="inputs flexbox direction-column">
        <mat-form-field class="mail w-100-percent no-background smaller-font">
          <mat-label>E-Mail</mat-label>
          <mat-icon matPrefix>email</mat-icon>
          <input
            matInput
            formControlName="email"
            type="email"
            id="email"
            [errorStateMatcher]="errorStateMatcher"
            data-cy="input-login-page-email"
          />
          @if (loginForm.get('email')?.hasError('email')) {
          <mat-error>Bitte geben Sie eine gültige E-Mail Adresse an</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="password w-100-percent no-background smaller-font">
          <mat-label>Passwort</mat-label>
          <mat-icon matPrefix>lock</mat-icon>
          <input matInput formControlName="password" type="password" autocomplete="on" id="password" data-cy="input-login-page-password" />
        </mat-form-field>
        <mat-checkbox
          formControlName="persistentLogin"
          class="persistent-check"
          color="primary"
          data-cy="checkbox-login-page-persistentLogin"
        >
          <span class="secondary-text">Angemeldet bleiben</span>
        </mat-checkbox>
      </div>

      @if ((_authService.loginMessageSubject$ | async)?.length) {
      <div class="login-message warn">
        {{ _authService.loginMessageSubject$ | async }}
      </div>
      }
      <button mat-flat-button color="primary" [disabled]="!loginForm.valid" class="rounded" data-cy="button-login-page-login">
        <span class="button-label">EINLOGGEN</span>
      </button>
    </form>
    <a
      class="forgot-password secondary-text"
      (click)="openForgotPasswordDialog()"
      (keyup.enter)="openForgotPasswordDialog()"
      tabindex="0"
      data-cy="link-login-page-forgot-password"
    >
      Passwort vergessen?
    </a>
    <section class="info">
      <a class="imprint secondary-text" href="https://www.eeule.de/impressum/" target="_blank">Impressum</a>
      <a class="agb secondary-text" href="https://www.eeule.de/eeule-software-richtlinien/eeule-software-agb/" target="_blank">AGB</a>
      <a class="agb secondary-text" href="https://www.eeule.de/eeule-software-richtlinien/eeule-software-avv/" target="_blank">AVV</a>
      <a class="privacy secondary-text" href="https://www.eeule.de/eeule-software-richtlinien/eeule-software-dse/" target="_blank"
        >Datenschutz</a
      >
    </section>
  </mat-card>
  }@else {
  <p>Es tut uns leid, die Anmeldung ist aktuell geschlossen</p>
  }} @if(store.signUpOpen()) {
  <section class="help flexbox direction-column small-gap">
    <div class="help-content no-account">
      <span>Sie haben noch kein Konto? </span>
      <a [routerLink]="'/signup'">Registrieren</a>
    </div>
    <div class="help-content contact">
      <span>Anmeldung nicht möglich? </span>
      <a href="https://www.eeule.de/#!/kontakt" target="_blank">Kontakt</a>
    </div>
  </section>
  }
</div>
