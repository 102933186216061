<h2 mat-dialog-title>Neuen Benutzer einladen</h2>
<mat-dialog-content>
  <mat-form-field class="w-100-percent">
    <mat-label>E-Mail des Benutzers</mat-label>
    <input matInput euleAutofocus [formControl]="invitedUserEmailControl" [attr.data-cy]="CY_ADDUSERDIALOG_INPUT_EMAIL" />
    @if (dataForm.get('invitedUserEmail')?.hasError('email')) {
    <mat-error>Bitte geben Sie eine gültige E-Mail Adresse an</mat-error>
    }
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    (click)="closeDialog(false, CY_ADDUSERDIALOG_BUTTON_CANCEL)"
    cdkFocusInitial
    [attr.data-cy]="CY_ADDUSERDIALOG_BUTTON_CANCEL"
  >
    Abbrechen
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="dataForm.invalid"
    (click)="closeDialog(true, CY_ADDUSERDIALOG_BUTTON_INVITE)"
    [attr.data-cy]="CY_ADDUSERDIALOG_BUTTON_INVITE"
  >
    Einladen
  </button>
</mat-dialog-actions>
