import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperModule, LoadedImage, OutputFormat } from 'ngx-image-cropper';
import { take, takeUntil } from 'rxjs';
import { SnackbarService } from '../../services/snackbar.service';
import { StorageService, allowedImageExtensions } from '../../services/storage.service';
import { BaseComponent } from '../base/base.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';

export interface UploadCroppedImageDialogComponentConfig {
  uploadPath: string;
  maintainAspectRatio: boolean;
  roundCropper: boolean;
  cropperMinWidth?: number;
  cropperMaxWidth?: number;
  aspectRatio?: number;
  format?: OutputFormat;
}

@Component({
  selector: 'eule-upload-cropped-image-dialog',
  standalone: true,
  imports: [ImageCropperModule, CommonModule, MatButtonModule, MatDialogModule, MatIconModule, MatInputModule],
  templateUrl: './upload-cropped-image-dialog.component.html',
  styleUrl: './upload-cropped-image-dialog.component.scss',
})
export class UploadCroppedImageDialogComponent extends BaseComponent {
  public allowedFileExtensions = allowedImageExtensions.map(ext => `.${ext}`).join(', ');
  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl | null = null;
  croppedImageBlob: Blob | null | undefined;

  constructor(
    private _analyticsService: AnalyticsService,
    public dialogRef: MatDialogRef<UploadCroppedImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadCroppedImageDialogComponentConfig,
    private sanitizer: DomSanitizer,
    private _storageService: StorageService,
    private _snackbarService: SnackbarService
  ) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileChangeEvent(event: Event | any): void {
    const file: File = event.target.files[0];
    if (file) {
      // if (!this._storageService.isImageUploadAllowed(file)) {
      //   this._snackbarService.showErrorMessage('Dateityp oder Dateigröße nicht erlaubt.');
      //   throw new Error('Dateityp oder Dateigröße nicht erlaubt.');
      // }
      if (!this._storageService.isImageExtensionAllowed(file)) {
        this._snackbarService.showErrorMessage('Dateitypnicht erlaubt.');
        throw new Error('Dateityp nicht erlaubt.');
      }
      if (!this._storageService.isImageFileSizeAllowed(file)) {
        this._snackbarService.showErrorMessage('Dateigröße nicht erlaubt.');
        throw new Error('Dateigröße nicht erlaubt.');
      }
      this.imageChangedEvent = event;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
    this.croppedImageBlob = event.blob!;
  }
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  imageLoaded(image: LoadedImage) {}

  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  public closeDialog(save: boolean): void {
    if (save && this.croppedImageBlob) {
      this._analyticsService.sendEvent('click', {
        category: 'button',
        label: 'upload-cropped-iange-dialog_button-save',
      });
      this._storageService
        .uploadImage(this.data.uploadPath, this.croppedImageBlob)
        .pipe(takeUntil(this.stop$), take(1))
        .subscribe(() => this.dialogRef.close(this.data.uploadPath));
    } else {
      this.dialogRef.close(undefined);
    }
  }
}
