import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { PaginatorIntlService } from './core/services/paginator-intl.service';
import { provideQuillConfig } from 'ngx-quill';
import { connectFirestoreEmulator } from 'firebase/firestore';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch()),
    provideAnimations(),

    // wrong place here, is being initialized in FirebaseService
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // FIXME: Redundant -> Firebase service constructor (is ignored)
    provideFirestore(() => {
      const firestore = getFirestore();
      if (!environment.production && environment.firebaseConfig.emulator.firestore) {
        const { host, port } = environment.firebaseConfig.emulator.firestore;
        connectFirestoreEmulator(firestore, host, port);
      }
      return firestore;
    }),
    provideAuth(() => getAuth()),

    provideQuillConfig({
      modules: {
        imageResize: {
          locale: {
            floatLeft: "←",
            floatRight: "→",
            center: "↔",
            restore: "↻",
          },
          showSize: true
        },
      },
      customOptions: [
        {
          import: 'formats/font',
          whitelist: ['roboto', 'mirza', 'aref', 'serif', 'sansserif', 'monospace'],
        },
      ],
    }),

    { provide: MatPaginatorIntl, useClass: PaginatorIntlService },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useFactory: () => navigator.language },
  ],
};
