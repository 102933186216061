import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';

@Component({
  selector: 'eule-questions-and-answers-page',
  standalone: true,
  imports: [MatButtonModule, MatCardModule, MatIconModule],
  templateUrl: './questions-and-answers-page.component.html',
  styleUrl: './questions-and-answers-page.component.scss',
})
export class QuestionsAndAnswersPageComponent {
  public constructor(private _analyticsService: AnalyticsService) {
    this._analyticsService.sendPageView('QuestionsAndAnswersPage');
  }

  public openWebsite(url: string) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }
}
