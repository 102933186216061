import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GeneralTitleComponent } from '../../../../core/components/general-title/general-title.component';
import { AppService, AppStoreApp } from '../../services/app.service';
import { map, Observable, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
interface IAppStoreApp extends AppStoreApp {
  isLoading: boolean;
}

@Component({
  selector: 'eule-dashboard',
  standalone: true,
  imports: [GeneralTitleComponent, MatProgressSpinnerModule, CommonModule, MatCardModule, MatProgressSpinnerModule],
  templateUrl: './app-dashboard.component.html',
  styleUrl: './app-dashboard.component.scss',
})
export class AppDashboardComponent {
  apps$: Observable<IAppStoreApp[]> = this._appService.getApps().pipe(
    take(1),
    map(apps =>
      apps.map(app => {
        return { ...app, isLoading: false } as IAppStoreApp;
      })
    )
  );
  constructor(private _route: ActivatedRoute, private _router: Router, private _appService: AppService) {}

  navigateToApp(app: IAppStoreApp) {
    app.isLoading = true; // Spinner nur für diese App setzen
    this._router.navigate([app.id], { relativeTo: this._route, queryParams: { ...app } }).finally(() => {
      app.isLoading = false; // Nach Abschluss wieder deaktivieren
    });
  }
}
