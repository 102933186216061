import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  standalone: true,
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, valueToIdentify: string, replaceWith: string = ''): string {
    if (typeof value !== 'string') return value;
    return value.includes(valueToIdentify) ? replaceWith : value;
  }
}
