import { Injectable } from '@angular/core';
import { collection, DocumentData, getDocs, QuerySnapshot } from 'firebase/firestore';
import { from, map, Observable } from 'rxjs';
import { FirebaseService } from '../../../core/services/firebase.service';

export interface AppStoreApp {
  id: string;
  name: string;
  subtitle?: string;
  url: string;
  description?: string;
  icon?: string;
}

/**
 * Service to load Data from (Web-)Apps eeule is in partnership with.
 *
 * @export
 * @class AppService
 */
@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private _firebaseService: FirebaseService) {}

  getApps(): Observable<AppStoreApp[]> {
    const colRef = collection(this._firebaseService.firestore, 'externalApps');
    return from(getDocs(colRef)).pipe(
      map((criteriaGroups: QuerySnapshot<DocumentData, DocumentData>) => criteriaGroups.docs.map(doc => doc.data() as AppStoreApp))
    );
  }
}
