<table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" class="differ-rows">
  <!--  Selection Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRowsSelection() : null"
        [checked]="this.rowSelection && isAllRowsSelected"
        [indeterminate]="!!this.rowSelection.selected.length && !isAllRowsSelected"
        [aria-label]="isAllRowsSelected ? 'Alles abwählen' : 'Alles auswählen'"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? this.rowSelection.toggle(row.id) : null"
        [checked]="this.rowSelection.isSelected(row.id)"
        [aria-label]="this.rowSelection.isSelected(row.id) ? 'Abwählen' : 'Auswählen'"
      >
      </mat-checkbox>
    </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{ 'disabled-text': row.userStatus === 'inactive' }">{{ row.email }}</span>
    </td>
  </ng-container>
  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{ 'disabled-text': row.userStatus === 'inactive' }">{{ row.status }}</span>
    </td>
  </ng-container>
  <!-- UserRole Column -->
  <ng-container matColumnDef="userRole">
    <th mat-header-cell *matHeaderCellDef>Benutzerrolle</th>
    <td mat-cell *matCellDef="let row; let i = index">
      <mat-select
        class="tertiary-background user-selection default-border-color"
        [(value)]="row.userRole"
        (selectionChange)="onUserRoleChange($event, row)"
      >
        @for (role of userRoleOptions; track role) {
        <mat-option
          [value]="role.value"
          [disabled]="role.value | roleOptionDisabled : row.userRole : usedLicenses : availableLicenses"
          [attr.data-cy]="CY_LICENSEADMINISTRATION_SELECT_ROLE + '_' + i"
        >
          {{ role.viewValue }}
        </mat-option>
        }
      </mat-select>
    </td>
  </ng-container>
  <!--  IsOwner Column-->
  <ng-container matColumnDef="isOwner">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Eigentümer</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{ 'disabled-text': row.userStatus === 'inactive' }">{{ row.isOwner }}</span>
    </td>
  </ng-container>
  <!--  IsPaidUser Column-->
  <ng-container matColumnDef="isPaidUser">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kostenpflichtig</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{ 'disabled-text': row.userStatus === 'inactive' }">{{ row.isPaidUser }}</span>
    </td>
  </ng-container>
  <!--  EntryDate Column-->
  <ng-container matColumnDef="entryDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Eintritt</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{ 'disabled-text': row.userStatus === 'inactive' }">{{ row.entryDate }}</span>
    </td>
  </ng-container>
  <!--  InvitedBy Column-->
  <ng-container matColumnDef="invitedBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Eingeladen von</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{ 'disabled-text': row.userStatus === 'inactive' }">{{
        row.invitedBy | replace : 'gserviceaccount' : 'System'
      }}</span>
    </td>
  </ng-container>
  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Aktionen</th>
    <td mat-cell *matCellDef="let row; let i = index">
      <section class="action-menu flexbox small-gap">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [attr.data-cy]="CY_LICENSEADMINISTRATION_BUTTON_MENU + '_' + i"
          class="open-context-menu"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          @if(!row.roles.includes('owner')) {
          <button
            mat-menu-item
            aria-label="Delete-Button"
            [disabled]="!_permissionService.hasRights('project_delete_users', 'project_update_users')"
            [attr.data-cy]="CY_LICENSEADMINISTRATION_BUTTON_DELETE + '_' + i"
            (click)="onDeleteUser(row)"
          >
            <mat-icon class="accent-2">delete</mat-icon>
            <span>Löschen</span>
          </button>
          <button
            mat-menu-item
            aria-label="Deactivate-Button"
            [disabled]="!_permissionService.hasRights('project_update_users')"
            [attr.data-cy]="CY_LICENSEADMINISTRATION_BUTTON_DEACTIVATE + '_' + i"
            (click)="onDeactivateUser(row)"
          >
            <mat-icon>
              {{ row.userStatus === 'active' ? 'person_off' : 'person' }}
            </mat-icon>
            <span>
              {{ row.userStatus === 'active' ? 'Deaktivieren' : 'Aktivieren' }}
            </span>
          </button>
          } @if(!row.roles.includes('owner')) {
          <button
            mat-menu-item
            aria-label="Owner-Button"
            [attr.data-cy]="CY_LICENSEADMINISTRATION_BUTTON_ADDOWNERSHIP + '_' + i"
            (click)="changeOwnership(row, true)"
          >
            <mat-icon>supervisor_account</mat-icon>
            <span>Zum Eigentümer machen</span>
          </button>
          } @if(row.roles.includes('owner')) {
          <button
            mat-menu-item
            aria-label="Owner-Button"
            [disabled]="!hasMoreThenOneOwner"
            [attr.data-cy]="CY_LICENSEADMINISTRATION_BUTTON_REMOVEOWNERSHIP + '_' + i"
            (click)="changeOwnership(row, false)"
          >
            <mat-icon>person_alert</mat-icon>
            <span>Eigentümer entfernen</span>
          </button>
          }
        </mat-menu>
      </section>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4" style="padding-top: 0.5rem">Es wurden keine Benutzer gefunden</td>
  </tr>
</table>

<mat-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [pageSize]="(_userService.euleUser$ | async)?.pagination"
  (page)="onChangePageSize($event.pageSize)"
  aria-label="Select page of users"
></mat-paginator>
