import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppCheckTokenResult, getToken } from 'firebase/app-check';
import { combineLatest, from, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth-christian/auth.service';
import { FirebaseService } from '../firebase.service';

export interface StripeCreateCustomerPortalSessionData {
  customerId: string;
  projectId: string;
  returnUrl?: string;
}

export interface StripeCustomerPortalSession {
  id: string;
  object: string;
  configuration: string;
  created: number;
  customer: string;
  flow: unknown;
  livemode: boolean;
  locale: unknown;
  on_behalf_of: unknown;
  return_url: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private _http: HttpClient, private _authService: AuthService, private _firebaseService: FirebaseService) {}

  public createCheckoutSession(priceId: string, internalCustomerId: string, internalProjectId: string) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callStripeCreateCheckoutSession';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
    const requestData = { metadata: { priceId: priceId, internalCustomerId: internalCustomerId, projectId: internalProjectId } };

    return this._http.post(functionUrl, requestData);
  }

  public createCustomerPortalSession(customerId: string, projectId: string): Observable<StripeCustomerPortalSession> {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callStripeCreateCustomerPortalSession';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
    const requestData: StripeCreateCustomerPortalSessionData = {
      customerId: customerId,
      projectId: projectId,
      returnUrl: window.location.href,
    };

    const token$: Observable<string> = from(this._authService.getAuthUser()!.getIdToken());
    return token$.pipe(
      switchMap((userIdToken: string) => {
        if (!userIdToken) {
          throw new Error('No ID token available.');
        }
        if (!this._firebaseService.appCheck) {
          return throwError(() => new Error('Error: appCheck is not initialized'));
        }
        return combineLatest([of(userIdToken), from(getToken(this._firebaseService.appCheck, false))]);
      }),
      switchMap(([userIdToken, appCheckTokenResult]: [string, AppCheckTokenResult]) =>
        this._http.post<StripeCustomerPortalSession>(functionUrl, requestData, {
          headers: {
            Authorization: `Bearer ${userIdToken}`, // Füge den Auth-Token-Header hinzu
            'X-Firebase-AppCheck': appCheckTokenResult.token,
          },
        })
      )
    );
  }
}
