import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { Observable, catchError, from, of, take } from 'rxjs';
import {
  EditProfilePictureDialogComponent,
  EditProfilePictureDialogComponentConfig,
} from '../../../profile/components/edit-profile-picture-dialog/edit-profile-picture-dialog.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { AuthService } from '../../services/auth-christian/auth.service';
import { PermissionService } from '../../services/permission.service';
import { StorageService } from '../../services/storage.service';

export const CY_PROFILEPICTURE_BUTTON_PROFILEPICTURE = 'confirm_dialog.button.profile_picture';

@Component({
  selector: 'eule-profile-picture',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatDialogModule],
  templateUrl: './profile-picture.component.html',
  styleUrl: './profile-picture.component.scss',
})
export class ProfilePictureComponent {
  CY_PROFILEPICTURE_BUTTON_PROFILEPICTURE = CY_PROFILEPICTURE_BUTTON_PROFILEPICTURE;
  @Input() matMenuTriggerFor!: MatMenu;
  @Input() isEditable: boolean = false;

  public profilePicture$: Observable<string | null> = of(null);

  public constructor(
    public _permissionService: PermissionService,
    private _editProfilePictureDialog: MatDialog,
    private _storageService: StorageService,
    private _authService: AuthService,
    private _analyticsService: AnalyticsService
  ) {
    this.profilePicture$ = from(this._storageService.downloadProfilePicture(this._authService.getAuth().currentUser!.uid)).pipe(
      take(1),
      catchError(() => {
        return of(null);
      })
    );

    this._storageService.fileChanges$.subscribe(() => (this.profilePicture$ = this._loadProfilePicture()));
  }

  public onClickProfilePicture() {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: CY_PROFILEPICTURE_BUTTON_PROFILEPICTURE,
    });
    if (!this.isEditable) return;

    const dialogRef = this._editProfilePictureDialog.open<
      EditProfilePictureDialogComponent,
      EditProfilePictureDialogComponentConfig,
      boolean | undefined
    >(EditProfilePictureDialogComponent, {
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      if (result) {
        this.profilePicture$ = this._loadProfilePicture();
      }
    });
  }

  private _loadProfilePicture(): Observable<string | null> {
    return from(this._storageService.downloadProfilePicture(this._authService.getAuth().currentUser!.uid)).pipe(
      take(1),
      catchError(err => {
        console.error(err);
        return of(null);
      })
    );
  }
}
