<div id="container">
  <div class="column user-info flexbox direction-column justify-center xs-gap">
    <div class="user-name primary">{{ getUserName() }}</div>
    @if ((userService.euleUser$ | async)?.title; as userTitle) {
    <div class="user-title">{{ userTitle }}</div>
    }
  </div>

  <eule-profile-picture
    [matMenuTriggerFor]="beforeMenu"
    (click)="clickProfilePicture()"
    [attr.data-cy]="CY_NAVIGATIONPROFILEBAR_COMPONENT_PROFILEPICTURE"
  ></eule-profile-picture>

  <mat-menu #beforeMenu="matMenu" xPosition="before">
    <div class="flexbox direction-column align-center justify-center">
      <button mat-menu-item (click)="navigate('profile')" [attr.data-cy]="CY_NAVIGATIONPROFILEBAR_BUTTON_PROFILE">
        <mat-icon>person</mat-icon>
        <span>Mein Profil</span>
      </button>
      <button mat-menu-item (click)="openContactPageInNewTab()" [attr.data-cy]="CY_NAVIGATIONPROFILEBAR_BUTTON_CONTACT">
        <mat-icon>contact_support</mat-icon>
        <span>Kontakt</span>
      </button>
      <div class="separator secondary-border-color-alt"></div>
      <div mat-menu-item>
        <mat-icon>bedtime</mat-icon>
        <mat-slide-toggle
          [formControl]="toggleControl"
          class="mr-8"
          [attr.data-cy]="CY_NAVIGATIONPROFILEBAR_TOGGLE_DARKMODE"
        ></mat-slide-toggle>
      </div>
      <button mat-menu-item (click)="logout()" [attr.data-cy]="CY_NAVIGATIONPROFILEBAR_BUTTON_LOGOUT">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </div>
  </mat-menu>
</div>
