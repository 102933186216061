<mat-card class="custom-card">
  <mat-card-header>
    <mat-card-title> Ihr Feedback an uns </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="card-text">
      <p>Gerne nehmen wir Ihre Rückmeldung zur Anwendung auf.</p>
      <p>Sie haben die Möglichkeit Änderungswünsche oder Erfahrungsberichte mit uns zu teilen oder einen Fehler zu melden.</p>
      <p>
        <span>Nutzen Sie bei sonstigen Rückfragen gerne unser </span>
        <a href="https://www.eeule.de/#kontakt" target="_blank" data-cy="link-feedback-page-contact">Kontaktformular</a><span>.</span>
      </p>
    </div>
    <div class="actions flexbox align-center small-gap">
      <button
        mat-raised-button
        color="primary"
        class="no-shadow"
        (click)="openFeedback()"
        matTooltip="Feedback geben"
        data-cy="button-feedback-page-feedback"
      >
        <mat-icon>thumb_up_alt</mat-icon>
        Feedback geben
      </button>
      <button
        mat-raised-button
        color="warn"
        class="no-shadow"
        (click)="openBug()"
        matTooltip="Einen Bugreport einreichen"
        data-cy="button-feedback-page-bugreport"
      >
        <mat-icon>bug_report</mat-icon>
        Fehler melden
      </button>
    </div>
  </mat-card-content>
</mat-card>
