import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabBody, MatTabsModule } from '@angular/material/tabs';
import { ProfilePictureComponent } from '../../core/components/profile-picture/profile-picture.component';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { UserService } from '../../core/services/user.service';
import { ProfileInformationComponent } from '../components/profile-information/profile-information.component';
import { ProfileSecurityComponent } from '../components/profile-security/profile-security.component';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';

@Component({
  selector: 'eule-profile-page',
  standalone: true,
  imports: [
    MatIconModule,
    MatTabsModule,
    ProfileInformationComponent,
    ProfileSecurityComponent,
    ProfilePictureComponent,
    MatDialogModule,
    MatCard,
    MatCardContent,
    MatTabBody,
    AsyncPipe,
  ],
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss',
})
export class ProfilePageComponent {
  public authUser = this._authService.getAuth().currentUser;

  constructor(private _analyticsService: AnalyticsService, public _userService: UserService, private _authService: AuthService) {
    this._analyticsService.sendPageView('ProfilePage');
  }
}
