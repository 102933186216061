import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

export interface StripeCustomerPortalSession {
  id: string;
  object: string;
  configuration: string;
  created: number;
  customer: string;
  flow: unknown;
  livemode: boolean;
  locale: unknown;
  on_behalf_of: unknown;
  return_url: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private _http: HttpClient) {}

  public createCheckoutSession(priceId: string, internalCustomerId: string, internalProjectId: string) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callStripeCreateCheckoutSession';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
    const requestData = { metadata: { priceId: priceId, internalCustomerId: internalCustomerId, projectId: internalProjectId } };

    return this._http.post(functionUrl, requestData);
  }

  public getPricingList() {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callGetPricingList';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
    const requestData = {};

    return this._http.post(functionUrl, requestData);
  }

  public createCustomerPortalSession(customerId: string): Observable<StripeCustomerPortalSession> {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callStripeCreateCustomerPortalSession';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
    const requestData = { customerId: customerId };

    return this._http.post<StripeCustomerPortalSession>(functionUrl, requestData);
  }
}
