import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { of, switchMap, tap } from 'rxjs';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { ProjectService, StripeInfo } from '../../../core/services/project.service';
import { StripeCustomerPortalSession, StripeService } from '../../../core/services/stripe/stripe.service';

@Component({
  selector: 'eule-subscription-page',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './subscription-page.component.html',
  styleUrl: './subscription-page.component.scss',
})
export class SubscriptionPageComponent {
  public constructor(
    private _projectService: ProjectService,
    private _stripeService: StripeService,
    private _analyticsService: AnalyticsService
  ) {
    this._analyticsService.sendPageView('SubscriptionPage');
  }

  public openCustomerPortal() {
    this._projectService
      .getStripeInfo()
      .pipe(
        switchMap((info: StripeInfo | null) => {
          if (info) {
            return this._stripeService.createCustomerPortalSession(info.customerId, this._projectService.project$.value!.id);
          } else {
            return of(null);
          }
        }),
        tap((session: StripeCustomerPortalSession | null) => {
          window.open(session?.url);
        })
      )
      .subscribe();
  }
}
