<mat-drawer-container id="main-drawer">
  <mat-drawer id="main-drawer-items" mode="side" opened class="sidenav flexbox direction-column">
    <div class="main-navigation">
      <mat-nav-list class="portal desktop">
        <!-- Disabled, coming later -->
        <!-- <a mat-list-item routerLink="./enterprise" routerLinkActive="is-active">
          <div class="nav-item-content">
            <mat-icon>language</mat-icon>
            <div class="nav-label">Enterprise</div>
          </div>
        </a> -->
        <a mat-list-item routerLink="./projects" routerLinkActive="is-active" data-cy="link-navigate-projects">
          <div class="nav-item-content">
            <mat-icon>view_cozy</mat-icon>
            <div class="nav-label">Projekte</div>
          </div>
        </a>
        @if (routeId$ | async) {
        <mat-divider class="side-nav-divider tertiary-border-color"></mat-divider>
        <a mat-list-item [routerLink]="'./project/' + (routeId$ | async) + '/home'" routerLinkActive="is-active" data-cy="link-navigate-home">
          <div class="nav-item-content">
            <mat-icon>home</mat-icon>
            <div class="nav-label">Home</div>
          </div>
        </a>
        <a mat-list-item [routerLink]="'./project/' + (routeId$ | async) + '/audit'" routerLinkActive="is-active" data-cy="link-navigate-audit">
          <div class="nav-item-content">
            <mat-icon>emoji_events</mat-icon>
            <div class="nav-label">Audit</div>
          </div>
        </a>
        <!-- <a mat-list-item [routerLink]="'./project/' + (routeId$ | async) + '/settings/subscription'" routerLinkActive="is-active" data-cy="link-navigate-audit">
          <div class="nav-item-content">
            <mat-icon>settings</mat-icon>
            <div class="nav-label">Verwaltung</div>
          </div>
        </a> -->
        }
      </mat-nav-list>
    </div>

    <div id="static-main-drawer-items">
      <div class="main-navigation">
        <mat-nav-list class="portal desktop">
          <a mat-list-item routerLink="help" (click)="_router.navigate(['/intern/feedback'])" data-cy="link-navigate-feedback">
            <div class="nav-item-content">
              <mat-icon>feedback</mat-icon>
              <div class="nav-label">Feedback</div>
            </div>
          </a>
          <!-- Disabled, coming later -->
          <!-- <a mat-list-item routerLink="help" (click)="router.navigate(['/intern/help'])">
            <div class="nav-item-content">
              <mat-icon>contact_support</mat-icon>
              <div class="nav-label">Hilfe</div>
            </div>
          </a> -->
          <a mat-list-item routerLink="logout" (click)="clickLogout()" data-cy="link-navigate-logout">
            <div class="nav-item-content">
              <mat-icon>logout</mat-icon>
              <div class="nav-label">Logout</div>
            </div>
          </a>
        </mat-nav-list>
      </div>
    </div>
  </mat-drawer>

  <!-- sub drawer & top bar -->
  <mat-drawer-content>
    <mat-toolbar id="title" class="flexbox smaller-gap default-border-color" [ngClass]="{ bordered: (theme$ | async) === 'default-theme' }">
      <div class="left-toolbar-content flexbox smaller-gap flex-grow-1">
        <div class="eeule-logo" id="logo">
          <img alt="logo" class="logo" src="./assets/images/eeule_logo_vertikal_144x46.png" width="144" height="46" />
        </div>
        @if ((_projectService.project$ | async)?.name; as projectName) {
        <div class="project-indicator flexbox direction-column">
          <div class="project-indication-header">Projekt</div>
          <div class="project-name primary">
            {{ projectName }}
          </div>
        </div>
        }
      </div>
      <eule-navigation-profile-bar></eule-navigation-profile-bar>
    </mat-toolbar>
    <mat-drawer-container id="sub-drawer" autosize>
      <mat-drawer [opened]="routeChild$ | async" id="sub-drawer-items" mode="side" class="context-nav">
        <mat-nav-list class="portal desktop">
          @if ((routeChild$ | async); as routeChild) {
          <!-- #drawer -->
          @switch (routeChild) { @case ('enterprise') {
          <a mat-list-item routerLinkActive="is-active">
            <div class="nav-item-content">
              <div class="nav-label">Start</div>
            </div>
          </a>
          <a mat-list-item routerLinkActive="is-active">
            <div class="nav-item-content">
              <div class="nav-label">Analyse</div>
            </div>
          </a>
          } @case ('home') {
          <a
            mat-list-item
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="'./project/' + (routeId$ | async) + '/home'"
            data-cy="link-navigate-home-start"
          >
            <div class="nav-item-content">
              <div class="nav-label">Start</div>
            </div>
          </a>
          <a
            mat-list-item
            routerLinkActive="is-active"
            [routerLink]="'./project/' + (routeId$ | async) + '/home/project-info'"
            role="button"
            tabindex="0"
            data-cy="link-navigate-home-project-info"
          >
            <div class="nav-item-content">
              <div class="nav-label">Projektdaten</div>
            </div>
          </a>
          <a
            mat-list-item
            routerLinkActive="is-active"
            [routerLink]="'./project/' + (routeId$ | async) + '/home/users'"
            role="button"
            tabindex="0"
            data-cy="link-navigate-home-users"
          >
            <div class="nav-item-content">
              <div class="nav-label">Benutzer</div>
            </div>
          </a>
          } @case ('audit') {
          <a
            mat-list-item
            routerLinkActive="is-active"
            [routerLink]="'./project/' + (routeId$ | async) + '/audit'"
            [routerLinkActiveOptions]="{ exact: true }"
            role="button"
            tabindex="0"
            data-cy="link-navigate-audit-start"
          >
            <div class="nav-item-content">
              <div class="nav-label">Start</div>
            </div>
          </a>
          <!--          <a mat-list-item>-->
          <!--            <div class="nav-item-content">-->
          <!--              <div class="nav-label">Zeitplan</div>-->
          <!--            </div>-->
          <!--          </a>-->
          <a
            mat-list-item
            routerLinkActive="is-active"
            [routerLink]="'./project/' + (routeId$ | async) + '/audit/tasks'"
            role="button"
            tabindex="0"
            data-cy="link-navigate-audit-tasks"
          >
            <div class="nav-item-content">
              <div class="nav-label">Aufgaben</div>
            </div>
          </a>
          <a
            mat-list-item
            routerLinkActive="is-active"
            [routerLink]="'./project/' + (routeId$ | async) + '/audit/documents'"
            role="button"
            tabindex="0"
            data-cy="link-navigate-audit-documents"
          >
            <div class="nav-item-content">
              <div class="nav-label">Dokumente</div>
            </div>
          </a>

          <!--                Pre-Check-->
          <mat-tree [dataSource]="preCheckDataSource" [treeControl]="preCheckTreeControl" class="example-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="sub-nav-item-child-node">
              @if (!node.nav) {
              {{ node.name }}
              } @else {
              <a mat-list-item routerLinkActive="is-active" [routerLink]="'./project/' + (routeId$ | async) + node.path" role="button" tabindex="0">
                <div class="sub-nav-item-content">
                  <div class="nav-label">{{ node.name }}</div>
                </div>
              </a>
              }
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <div class="mat-tree-node">
                <a mat-list-item routerLinkActive="is-active" [routerLink]="'./project/' + (routeId$ | async) + node.path">
                  <div class="nav-item-content">
                    <div class="nav-label flexbox align-center justify-spaced w-100-percent">
                      {{ node.name }}
                      <button
                        mat-icon-button
                        matTreeNodeToggle
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                        class="node-toggle medium-button"
                        [attr.aria-label]="'Toggle ' + node.name"
                      >
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{ preCheckTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                      </button>
                    </div>
                  </div>
                </a>
              </div>
              <div [class.example-tree-invisible]="!preCheckTreeControl.isExpanded(node)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>

          <!--                Audit-->
          <mat-tree [dataSource]="auditDataSource" [treeControl]="auditTreeControl" class="example-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="sub-nav-item-child-node">
              @if (!node.nav) {
              {{ node.name }}
              } @else {
              <a mat-list-item routerLinkActive="is-active" [routerLink]="'./project/' + (routeId$ | async) + node.path" role="button" tabindex="0">
                <div class="sub-nav-item-content">
                  <div class="nav-label">{{ node.name }}</div>
                </div>
              </a>
              }
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <div class="mat-tree-node">
                <a mat-list-item routerLinkActive="is-active" [routerLink]="'./project/' + (routeId$ | async) + node.path">
                  <div class="nav-item-content">
                    <div class="nav-label flexbox align-center justify-spaced w-100-percent">
                      {{ node.name }}
                      <button
                        mat-icon-button
                        matTreeNodeToggle
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                        class="node-toggle medium-button"
                        [attr.aria-label]="'Toggle ' + node.name"
                      >
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{ auditTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                      </button>
                    </div>
                  </div>
                </a>
              </div>
              <div [class.example-tree-invisible]="!auditTreeControl.isExpanded(node)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>
          }@case ('settings') {
          <a
            mat-list-item
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="'./project/' + (routeId$ | async) + '/settings/subscription'"
            data-cy="link-navigate-settings-subscription"
          >
            <div class="nav-item-content">
              <div class="nav-label">Abonnement</div>
            </div>
          </a>
          } @case ('tool') {
          <a mat-list-item>
            <div class="nav-item-content">
              <div class="nav-label">Start</div>
            </div>
          </a>
          } } }
        </mat-nav-list>
      </mat-drawer>

      <div id="sidenav-content" class="flexbox direction-column">
        <router-outlet class="page-outlet"></router-outlet>
        <eule-footer></eule-footer>
      </div>
    </mat-drawer-container>
  </mat-drawer-content>
</mat-drawer-container>
