import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { tap } from 'rxjs';
import { BaseComponent } from '../../../../../../core/components/base/base.component';
import { AnalyticsService } from '../../../../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../../../../core/services/project.service';
import { UsageProfileEnum } from '../../../../../../enums/UsageProfile.enum';
import {
  MixedUsageProfileFloorGroup,
  Tooltip,
  UsageProfileParametersFormGroup,
} from '../../../project-info-page/project-info-page.component';
import {
  FloorParametersDialogComponent,
  FloorParametersDialogComponentConfig,
  FloorParametersDialogComponentResult,
} from '../floor-parameters-dialog.component.ts/floor-parameters-dialog.component';
import { CustomTooltipDirective } from '../../../../../../core/directives/custom-tooltip.directive';

@Component({
  selector: 'eule-usage-profile-parameters',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    ReactiveFormsModule,
    CustomTooltipDirective,
  ],
  templateUrl: './usage-profile-parameters.component.html',
  styleUrl: './usage-profile-parameters.component.scss',
})
export class UsageProfileParametersComponent extends BaseComponent {
  @Input({ required: true }) usageProfileFormGroup!: FormGroup<UsageProfileParametersFormGroup>;

  @Output() usageProfileChange: EventEmitter<void> = new EventEmitter<void>();

  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;

  get getUsageProfileId(): string {
    return this.usageProfileFormGroup.get('usageProfile')?.value || '';
  }
  get getUsageProfileType(): string {
    return this.usageProfileFormGroup.get('usageProfileType')?.value || '';
  }
  get floors(): FormArray<FormGroup<MixedUsageProfileFloorGroup>> {
    return this.usageProfileFormGroup.get('floors') as FormArray<FormGroup<MixedUsageProfileFloorGroup>>;
  }

  public getFloorAtIndex(floorIndex: number): FormGroup<MixedUsageProfileFloorGroup> {
    return this.floors.at(floorIndex);
  }

  public tooltips: Tooltip = {
    hasMixedUsageProfiles: `Mischnutzung
    Befinden sich im betrachteten Gebäude mehrere unterschiedliche Nutzungen, muss überprüft werden, welches Nutzungsprofil anzuwenden ist. Grundlage für die Einschätzung, ob die Zertifizierung nach MIX23 stattfinden muss, bildet die DGNB Bemessungsfläche.
    Es wird empfohlen die Einstufungen unterschiedlicher Nutzungen mit der DGNB Geschäftsstelle abzustimmen.
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
    sideUsage: `Eine oder mehrere Nutzungen, die einem anderen Nutzungsprofil als der Hauptnutzung zugeordnet werden und deren Flächenanteil an der gesamten DGNB Bemessungsfläche ≥ 15% beträgt, wird als Nebennutzung bezeichnet. Die Flächen einer Nebennutzung müssen mit dem entsprechenden Nutzungsprofil bewertet werden. `,
    mainUsage: `Das Nutzungsprofil mit dem größten Flächenanteil an der gesamten DGNB Bemessungsfläche wird als Hauptnutzung bezeichnet. Ist die Einstufung nicht eindeutig möglich, ist die Hauptnutzung festzulegen und die Entscheidung zu begründen.`,
    flaechenberechnungNachDin277: `<h3>Flächenberechnung nach DIN 277</h3>
    <p><strong>BGF(R) = KGF(R) + NRF(R)</strong></p>
    <p><strong>NRF(R) = NUF(R) + TF(R) + VF(R)</strong></p>
    <ul>
    <li>BGF: Brutto-Grundfläche</li>
    <li>KGF: Konstruktions-Grundfläche</li>
    <li>NRF: Netto-Raumfläche</strong></li>
    <li>NUF: Nutzungsfläche</li>
    <li>TF : Technikfläche</li>
    <li>VF : Verkehrsfläche</li></ul>`,
    bemessungsflaeche: `<h3>DGNB Bemessungsfläche</h3>
    <p><strong>A<sub>DGNB</sub> = NUF<sub>a</sub> - NUF<sub>a,7,4</sub> + VF<sub>a,9,1</sub></strong></p>
    <ul><li>A<sub>DGNB</sub>: DGNB Bemessungsfläche</li>
    <li>NUF<sub>a</sub>: Nutzungsfläche nach DIN277</li>
    <li>NUF<sub>a,7,4</sub>: Fahrzeugabstellfläche nach DIN 277</li>
    <li>VF<sub>a,9,1</sub>: Verkehrsfläche Flure und Hallen nach DIN277</li></ul>
    <p>Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.</p>`,
  };

  public constructor(private _analyticsService: AnalyticsService, public projectService: ProjectService, private _dialog: MatDialog) {
    super();
  }

  public openFloor(index: number | null = null) {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: 'usage-profile-parameters-component_button-open-floor',
    });
    const _data: FloorParametersDialogComponentConfig = { form: null, editMode: false };
    if (index !== null && index !== undefined) {
      _data.form = this.getFloorAtIndex(index!);
      _data.editMode = true;
    }

    const dialogRef = this._dialog.open(FloorParametersDialogComponent, {
      data: _data,
      minWidth: '620px',
      maxWidth: '820px',
      minHeight: '400px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        tap((dialogResult: FloorParametersDialogComponentResult) => {
          if (dialogResult !== null && dialogResult !== undefined) {
            if (!dialogResult.editMode && dialogResult.form) {
              // if the Dialog was in "add new floor" mode, add the new floor form to the floors array
              this.floors.push(dialogResult.form);
              this.usageProfileFormGroup.markAsDirty();
            }
            this.usageProfileChange.emit();
          }
        })
      )
      .subscribe();
  }

  public deleteFloor(index: number) {
    this.floors.removeAt(index);
    this.usageProfileChange.emit();
    this.usageProfileFormGroup.markAsDirty();
  }
}
