import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { from, Observable, of, switchMap } from 'rxjs';
import { AppStoreApp } from '../../../projects/apps/services/app.service';
import { SnackbarService } from '../../services/snackbar.service';

/**
 * Guard that checks if the desired website is a valid url and allows iframe.
 *
 * @param {ActivatedRouteSnapshot} route - The activated route snapshot.
 * @returns {Observable<boolean>} An observable emitting true if the user has the necessary permissions, false otherwise.
 */
export const isValidIframeAppGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const _snackbarService = inject(SnackbarService);
  const appData: AppStoreApp = route.queryParams as AppStoreApp;
  if (!appData.url) {
    _snackbarService.showErrorMessage('Keine App URL');
    return of(false);
  }
  if (!appData.url.startsWith('https')) {
    _snackbarService.showErrorMessage('Keine sichere URL');
    return of(false);
  }

  return from(_checkIframeSupport(appData.url)).pipe(
    switchMap(isAllowed => {
      if (!isAllowed) {
        _snackbarService.showErrorMessage('App erlaubt keinen iframe');
        return of(false);
      }
      return of(true);
    })
  );
};

function _checkIframeSupport(rawUrl: string): Promise<boolean> {
  return new Promise(resolve => {
    const testIframe = document.createElement('iframe');
    testIframe.style.display = 'none'; // Unsichtbar machen, um UI-Störungen zu vermeiden
    testIframe.src = rawUrl;

    testIframe.onload = () => {
      document.body.removeChild(testIframe);
      resolve(true); // Falls das iframe geladen wird → URL erlaubt iframes
    };

    testIframe.onerror = () => {
      document.body.removeChild(testIframe);
      resolve(false); // Falls das iframe blockiert wird → URL erlaubt keine iframes
    };

    document.body.appendChild(testIframe);
  });
}
