<eule-general-title [title]="'Apps'"> </eule-general-title>

@if (apps$ | async; as appList) { @if(appList.length){
<div class="app-container">
  @for (app of appList; track app) {
  <div class="app-card">
    <mat-card class="custom-card" (click)="navigateToApp(app)">
      @if (app.isLoading) {
      <div class="overlay">
        <mat-spinner></mat-spinner>
      </div>
      }
      <div>
        <img alt="logo" class="logo" [src]="app.icon" />
      </div>
      <div class="card-content-wrapper" [class.isLoading]="app.isLoading">
        <mat-card-header>
          <mat-card-title>
            <span class="description">{{ app.name }}</span>
          </mat-card-title>
          <mat-card-subtitle>{{ app.subtitle || '' }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="card-content">
          {{ app.description || '' }}
        </mat-card-content>
      </div>
    </mat-card>
  </div>
  }
</div>
} @else {
<h3>Keine Apps verfügbar</h3>
} } @else {
<div class="loading-overlay">
  <mat-spinner diameter="50"></mat-spinner>
</div>
}
