import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs';

export interface ExportAsCsvData {
  header: Array<{ id: string, title: string }>;
  rows: Array<{ [key: string]: unknown }>;
  fileName?: string;
  separator?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(private _http: HttpClient) {}

  /**
   * Downloads the Data as CSV
   *
   * @returns
   *
   * @memberOf CsvService
   */
  public downloadCsv(requestData?: ExportAsCsvData) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callExportAsCSV';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    return this._http.post(functionUrl, requestData, { responseType: 'blob' }).pipe(
      tap(blob => {
        // Erzeuge eine URL für den Blob-Inhalt
        const url = window.URL.createObjectURL(blob);

        // Erstelle einen temporären Link
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv'; // Dateiname festlegen
        document.body.appendChild(a);
        a.click();

        // Link entfernen und Speicher freigeben
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    );
  }
}
