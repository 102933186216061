import { Injectable, inject } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  DgnbSystem,
  Permission,
  Project,
  RoleAssignment,
  StripeProjectInfo,
  UsageProfile,
  UsageProfileEnum,
  User,
} from '@eeule/eeule-shared';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, catchError, combineLatestWith, forkJoin, map, mergeMap, of, switchMap, tap, throwError } from 'rxjs';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { IndicatorService } from '../../core/services/indicator.service';
import { PermissionService } from '../../core/services/permission.service';
import { ProjectService } from '../../core/services/project.service';
import { RoleService } from '../../core/services/role.service';
import { ErrorLog, SnackbarService } from '../../core/services/snackbar.service';
import { UsageProfileService } from '../../core/services/usage-profiles/usage-profile.service';
import { ProjectUserDisplay, UserService } from '../../core/services/user.service';
import { CollectionQueryResponse, DocumentQueryResponse, FirebaseDocumentData } from '../../types/firebase-types';
import { ProjectTableData } from './project-list.component';

export interface ProjectDisplay extends Project {
  owners: Array<ProjectUserDisplay>;
  resolvedDgnbSystem?: DgnbSystem;
  currentUserCanDelete?: boolean;
  currentUserCanReadStripeInfo?: boolean;
}

export type UserWithRoleAssignment = ProjectUserDisplay & { roleAssignment?: RoleAssignment | null | undefined };
export type ProjectDisplayWithOwners = (Project | ProjectDisplay) & { owners?: UserWithRoleAssignment[] };
export type ProjectDisplayWithStripeInfo = (Project | ProjectDisplay) & { stripeInfo?: StripeProjectInfo | null };

interface ProjectListState {
  error: unknown;
  projects: ProjectTableData[];
  isLoading: boolean;
}

@Injectable()
export class ProjectListStore extends ComponentStore<ProjectListState> {
  private _userService = inject(UserService);
  private _projectService = inject(ProjectService);
  private _authService = inject(AuthService);
  private _roleService = inject(RoleService);
  private _indicatorService = inject(IndicatorService);
  private _permissionService = inject(PermissionService);
  private _snackbarService = inject(SnackbarService);
  private _usageProfilesService = inject(UsageProfileService);

  readonly projects = toSignal(
    this.select(state => state.projects),
    { initialValue: [] }
  );

  readonly isLoading = toSignal(
    this.select(state => state.isLoading),
    { initialValue: false }
  );
  readonly error = toSignal(
    this.select(state => state.error),
    { initialValue: null }
  );

  constructor() {
    super({ error: null, projects: [], isLoading: false });
  }

  readonly euleUser = this._userService.euleUserSignal;

  private readonly _loadProjectsEffect = this.effect(() =>
    toObservable(this.euleUser).pipe(
      tap(() => this.patchState({ isLoading: true })),
      switchMap((user: User | null) => this._getUsersPermissionedProjectIds(user)),
      switchMap(projectIds => this._getProjects(projectIds)),
      map(projects => projects.filter(proj => !proj.softDeleted)), // `softDeleted` rausfiltern
      mergeMap(projects => this._resolveProjectOwners(projects)), // Owner auflösen
      mergeMap(projects => this._resolveDgnbSystems(projects)), // Zertifizierungssysteme auflösen
      mergeMap(projects => this._resolveCurrentUserPermissions(projects)), // Berechtigungen auflösen
      mergeMap(projects => this._resolveStripeInfos(projects)), // Projektbezogene Stripeinfos auflösen
      combineLatestWith(this._usageProfilesService.getUsageProfiles()),
      map(([projects, usageProfiles]: [ProjectDisplay[], CollectionQueryResponse<UsageProfile>]) =>
        this._resolveNames(projects, usageProfiles)
      ),
      tap(projects => this.patchState({ projects, isLoading: false })),
      catchError(error => {
        this._snackbarService.showErrorMessage('Beim Initialisieren der Daten ist ein Fehler aufgetreten.');
        return throwError(() => error);
      })
      // takeUntil(this.stop$)
    )
  );

  private _getUsersPermissionedProjectIds(user: User | null): Observable<string[]> {
    if (!user) return of([]); // TODO: throwError
    return this._permissionService.getUserPermissionedProjectIds(user.id).pipe(
      catchError(error => {
        const _errorLog = {
          message: 'Error loading users permissioned projectIds',
          data: {
            userId: user.id,
            originalError: error,
          },
        };
        this._snackbarService.showErrorMessage(`Fehler beim Laden der erlaubten ProjektIds von User ${user.id} ${error}`, 5000, _errorLog);
        return throwError(() => _errorLog);
      })
    );
  }

  private _getProjects(projectIds: Array<string>): Observable<Project[]> {
    return this._projectService.getProjectsByIds(projectIds).pipe(
      catchError(error => {
        const _errorLog = {
          message: 'Error loading projects by Ids',
          data: {
            projectIds: projectIds,
            originalError: error,
          },
        };
        this._snackbarService.showErrorMessage(
          `Fehler beim Laden eines oder mehrerer Projekte aus Array ${projectIds}: ${error}`,
          5000,
          _errorLog
        );
        return throwError(() => _errorLog);
      })
    );
  }

  private _resolveProjectOwners(projects: Project[] | ProjectDisplay[]): Observable<ProjectDisplayWithOwners[]> {
    if (!projects?.length) return of([]);

    const projectWithOwners$: Observable<ProjectDisplayWithOwners>[] = projects.map(proj =>
      this._projectService.getAllProjectUsersDisplay(proj.id).pipe(
        catchError(error => {
          const _errorLog = {
            message: 'Error loading users from project',
            data: {
              projects: projects,
              originalError: error,
            },
          };
          this._snackbarService.showErrorMessage(`Fehler beim Laden der user aus Projekt ${proj.id}: ${error}`, 5000, _errorLog);
          return throwError(() => _errorLog);
        }),
        mergeMap((users: ProjectUserDisplay[]) => {
          if (!users?.length) return of([]);

          const usersWithRoleAssignments$: Observable<UserWithRoleAssignment>[] = users.map((user: ProjectUserDisplay) => {
            return this._roleService.getProjectRoleAssignmentByUserId(proj.id, user.id).pipe(
              map((roleAssignment: DocumentQueryResponse<RoleAssignment>) => {
                return { ...user, roleAssignment: roleAssignment?.data || undefined };
              }),
              catchError(error => {
                const _errorLog = {
                  message: 'Error loading users roleAssignments',
                  data: {
                    user: user,
                    originalError: error,
                  },
                };
                this._snackbarService.showErrorMessage(
                  `Fehler beim Laden der roleAssignments des users (userId: ${user.id}):  ${error}`,
                  5000,
                  _errorLog
                );
                return throwError(() => _errorLog);
              })
            );
          });
          return forkJoin(usersWithRoleAssignments$);
        }),
        map((usersWithRoleAssignments: UserWithRoleAssignment[]) => ({
          ...proj,
          owners: usersWithRoleAssignments.filter(user => user.roleAssignment?.roles?.some(role => role.id === 'owner')),
        }))
      )
    );

    return forkJoin(projectWithOwners$);
  }

  private _resolveDgnbSystems(projects: Project[] | ProjectDisplay[]): Observable<ProjectDisplay[]> {
    if (!projects.length) return of([]);
    const certificationSystemRequests: Observable<DgnbSystem>[] = projects
      .filter(proj => proj.dgnbSystem)
      .map(proj => this._indicatorService.getDgnbSystem(proj.dgnbSystem));
    return forkJoin(certificationSystemRequests).pipe(
      map(certificationSystems =>
        projects.map(
          project =>
            ({
              ...project,
              resolvedDgnbSystem: certificationSystems.find(sys => sys.id === project.dgnbSystem),
            } as ProjectDisplay)
        )
      )
    );
  }

  private _resolveCurrentUserPermissions(projects: ProjectDisplay[]): Observable<ProjectDisplay[]> {
    if (!this._authService.authUserIdSignal()) return of([]); // TODO: ist diese Zeile noch nötig? TODO Fehler anzeigen und werfen
    const projectWithRights: Observable<ProjectDisplay>[] = projects.map(project => {
      return this._permissionService.getUserProjectPermissions(this._authService.authUserIdSignal()!, project.id).pipe(
        map((permissions: DocumentQueryResponse<Permission>) => {
          return {
            ...project,
            currentUserCanDelete: !!permissions?.data?.rights.includes('project_view_soft_delete'),
            currentUserCanReadStripeInfo: !!permissions?.data?.rights.includes('project_read_stripe_info'),
          };
        })
      );
    });

    if (!projectWithRights.length) return of([]);
    return forkJoin(projectWithRights);
  }
  private _resolveStripeInfos(projects: ProjectDisplay[]): Observable<ProjectDisplayWithStripeInfo[]> {
    const projectsWithStripeInfo: Observable<ProjectDisplayWithStripeInfo>[] = projects.map((project: ProjectDisplay) => {
      if (!project.currentUserCanReadStripeInfo) return of(project); // Sicherheitsabfrage ob der aktuelle User überhaupt die StripeInfo lesen darf
      return this._projectService.getStripeInfoByProjectId(project.id).pipe(
        map((stripeInfo: StripeProjectInfo | null) => {
          return {
            ...project,
            stripeInfo: stripeInfo,
          } as ProjectDisplayWithStripeInfo;
        }),
        catchError(error => {
          const _errorLog: ErrorLog = {
            message: error.message,
            data: {
              originalError: error,
            },
          };
          this._snackbarService.showErrorMessage(`Fehler beim Laden der Aboinformationen zu Projekt ${project.id}`, 5000, _errorLog);
          return of(project);
        })
      );
    });

    if (!projectsWithStripeInfo.length) return of([]);
    return forkJoin(projectsWithStripeInfo);
  }

  private _resolveNames(projects: ProjectDisplay[], usageProfiles: CollectionQueryResponse<UsageProfile>): ProjectTableData[] {
    return (
      projects.map((project: ProjectDisplay) => {
        return {
          ...project,
          projectOwners:
            project.owners.map((owner: ProjectUserDisplay) => {
              if (owner.firstName && owner.lastName) {
                return `${owner.firstName} ${owner.lastName}`;
              }
              return owner.email || '---';
            }) || [],
          usageProfileKeys:
            usageProfiles.data
              ?.filter((profile: FirebaseDocumentData<UsageProfile>) => {
                return project.usageProfiles.map(pup => pup.usageProfile).some(pup => pup === profile.id);
              })
              .map((up: FirebaseDocumentData<UsageProfile>) => up.name as UsageProfileEnum) || [],
        };
      }) || []
    );
  }
}
