import { CommonModule } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FieldValue, serverTimestamp, Timestamp } from 'firebase/firestore';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { NavigationHistoryService } from '../../../core/services/navigation-history.service';
import { ErrorLog } from '../../../core/services/snackbar.service';
import { UserService } from '../../../core/services/user.service';

export const CY_BUGREPORTDIALOG_BUTTON_CANCEL = 'bug_report_dialog.button.cancel';
export const CY_BUGREPORTDIALOG_BUTTON_SAVE = 'bug_report_dialog.button.save';

interface BugReportForm {
  email: FormControl<string | null>;
  creatorId: FormControl<string | null>;
  description: FormControl<string | null>;
  expectedBehaviour: FormControl<string | null>;
  actualBehaviour: FormControl<string | null>;
  date: FormControl<FieldValue | Timestamp | null>;
  errorLog: FormControl<ErrorLog | null | undefined>;
  navigationHistory: FormControl<string[] | null>;
}

export interface BugReportDialogComponentConfig {}
export interface BugReportDialogComponentResult {
  id?: string;
  email: string;
  description: string;
  expectedBehaviour: string;
  actualBehaviour: string;
  creatorId: string;
  date: FieldValue | Timestamp;
  errorLog: FormControl<ErrorLog | null | undefined>;
  navigationHistory: FormControl<string[]>;
}

@Component({
  selector: 'eule-bug-report-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './bug-report-dialog.component.html',
  styleUrl: './bug-report-dialog.component.scss',
})
export class BugReportDialogComponent {
  CY_BUGREPORTDIALOG_BUTTON_CANCEL = CY_BUGREPORTDIALOG_BUTTON_CANCEL;
  CY_BUGREPORTDIALOG_BUTTON_SAVE = CY_BUGREPORTDIALOG_BUTTON_SAVE;

  private _navigationHistoryService = inject(NavigationHistoryService);

  public constructor(
    private _analyticsService: AnalyticsService,
    private _dialogRef: MatDialogRef<BugReportDialogComponent>,
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data?: ErrorLog
  ) {}

  public bugReportForm: FormGroup<BugReportForm> = this._formBuilder.group({
    email: this._formBuilder.control(this._userService.euleUser$.value!.email, [Validators.required]),
    creatorId: this._formBuilder.control(this._userService.euleUser$.value!.id, [Validators.required]),
    description: this._formBuilder.control('', Validators.required),
    expectedBehaviour: this._formBuilder.control('', Validators.required),
    actualBehaviour: this._formBuilder.control('', Validators.required),
    date: this._formBuilder.control(serverTimestamp()),
    errorLog: this._formBuilder.control({ value: this.data, disabled: true }), // not visible for user in UI
    navigationHistory: this._formBuilder.control({ value: this._navigationHistoryService.getHistory(), disabled: true }), // not visible for user in UI
  });

  public closeDialog(save: boolean) {
    if (save) {
      this._analyticsService.sendEvent('click', {
        category: 'button',
        label: this.CY_BUGREPORTDIALOG_BUTTON_SAVE,
      });
      this._dialogRef.close(this.bugReportForm.getRawValue());
    } else {
      this._analyticsService.sendEvent('click', {
        category: 'button',
        label: this.CY_BUGREPORTDIALOG_BUTTON_CANCEL,
      });
      this._dialogRef.close(null);
    }
  }
}
