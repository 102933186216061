import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BaseComponent } from '../../core/components/base/base.component';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { LoginStore } from './login.store';

type LoginForm = {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  persistentLogin: FormControl<boolean | null>;
};

@Component({
  selector: 'eule-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgOptimizedImage,
    AsyncPipe,
    NgClass,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
  ],
  providers: [LoginStore],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent extends BaseComponent {
  public store: LoginStore = inject(LoginStore);

  loginForm: FormGroup<LoginForm> = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    persistentLogin: [false],
  });

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.HandsetPortrait).pipe(
    map(result => result.matches),
    shareReplay()
  );

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Error state matcher for form validation. */
  errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();

  constructor(
    public _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog,
    private _breakpointObserver: BreakpointObserver
  ) {
    super();
    this._authService.cleanupOnLoginAndSignup();
  }

  submit() {
    if (this.loginForm.valid) {
      this.store.submitLoginEffect({
        email: this.loginForm.value.email!,
        password: this.loginForm.value.password!,
        persistentLogin: !!this.loginForm.value.persistentLogin,
      });
    }
  }

  openForgotPasswordDialog() {
    this._dialog.open(ForgotPasswordComponent, { width: '400px' });
  }
}
