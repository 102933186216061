import { Routes } from '@angular/router';
import { _authGuard } from './core/guards/auth-guard-christian/auth.guard';
import { canDeactivateGuard } from './core/guards/auth-guard-christian/canDeactivate.guard';
import { _loadProjectGuard } from './core/guards/project-loader/project-loader.guard';
import { redirectGuard } from './core/guards/redirect-guard/redirect.guard';
import { NavigationComponent } from './core/navigation/navigation.component';
import { setAuditDependenciesResolver } from './core/resolvers/set-audit-dependencies.resolver';
import { FeedbackComponent } from './global/feedback/feedback.component';
import { LogoutComponent } from './global/logout/logout.component';
import { SettingsComponent } from './global/settings/settings.component';
import { LoginComponent } from './login/login-page/login.component';
import { ProfilePageComponent } from './profile/profile-page/profile-page.component';
import { AuditDocumentsPageComponent } from './projects/audit/audit-documents-page/audit-documents-page.component';
import { AuditStartPageComponent } from './projects/audit/audit-start-page/audit-start-page.component';
import { AuditTasksPageComponent } from './projects/audit/audit-tasks-page/audit-tasks-page.component';
import { HomeComponent } from './projects/home/home.component';
import { ProjectInfoPageComponent } from './projects/home/project-info-page/project-info-page/project-info-page.component';
import { UsersListPageComponent } from './projects/home/users-list-page/users-list-page.component';
import { ProjectListComponent } from './projects/project-list/project-list.component';
import { SubscriptionPageComponent } from './projects/settings/subscription-page/subscription-page.component';
import { ToolComponent } from './projects/tool/tool.component';
import { SignupPageComponent } from './signup/signup-page/signup-page.component';
import { SubscriptonPageComponent } from './subscription/subscripton-page/subscripton-page.component';
import { VerifyPageComponent } from './verify/verify-page/verify-page.component';

export const routes: Routes = [
  // { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' }, // default route while enterprise does not exist
  {
    path: 'landing',
    redirectTo: 'login',
  },
  {
    path: 'login',
    canActivate: [redirectGuard],
    component: LoginComponent,
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'signup', component: SignupPageComponent },
  { path: 'verify', component: VerifyPageComponent },
  {
    path: 'intern',
    component: NavigationComponent,
    canActivate: [_authGuard],
    children: [
      // Disabled, coming later
      // {
      //   path: 'enterprise',
      //   component: OverviewComponent,
      // },
      {
        path: 'feedback',
        component: FeedbackComponent,
      },
      // Disabled, coming later
      // {
      //   path: 'help',
      //   component: HelpComponent,
      // },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'projects',
        component: ProjectListComponent,
      },
      {
        path: 'project/:id',
        canActivate: [_loadProjectGuard], // loads all necessary Data when a project page is browsed (could also be a Resolver)
        children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          {
            path: 'home',
            component: HomeComponent,
          },
          {
            path: 'home',
            children: [
              // { path: '', redirectTo: 'start', pathMatch: 'full' }, //default route
              {
                path: 'start',
                component: HomeComponent,
              },
              {
                path: 'project-info',
                component: ProjectInfoPageComponent,
                canDeactivate: [canDeactivateGuard],
              },
              { path: 'company', component: HomeComponent },
              { path: 'users', component: UsersListPageComponent },
              { path: 'digital-twin', component: HomeComponent },
              { path: 'leistungsphasen', component: HomeComponent },
              { path: '**', component: AuditStartPageComponent },
            ],
          },
          {
            path: 'audit',
            resolve: { dep: setAuditDependenciesResolver },
            children: [
              { path: 'start', component: AuditStartPageComponent },
              { path: 'tasks', component: AuditTasksPageComponent },
              { path: 'documents', component: AuditDocumentsPageComponent },
              {
                path: 'pre-check',
                loadChildren: () => import('./projects/audit/scenario-subjects.routes').then(mod => mod.preCheckSubjectsRoutes),
              },
              {
                path: 'audit-details',
                loadChildren: () => import('./projects/audit/scenario-subjects.routes').then(mod => mod.auditSubjectsRoutes),
              },
              { path: '**', component: AuditStartPageComponent },
            ],
          },
          {
            path: 'settings',
            children: [
              { path: 'subscription', component: SubscriptionPageComponent },
              { path: '**', component: SubscriptionPageComponent },
            ],
          },
          { path: 'tool', component: ToolComponent },
        ],
      },
      {
        path: 'profile',
        component: ProfilePageComponent,
      },
      {
        path: 'subscription',
        component: SubscriptonPageComponent,
      },
      // { path: '', redirectTo: 'enterprise', pathMatch: 'full' }, //default route
      { path: '**', component: ProjectListComponent },
    ],
  },
];
