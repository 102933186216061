<!-- fixme should be maybe adjusted, show loading indication if image is fetching and placeholder if imageUrl is null -->
<button
  mat-button
  [matMenuTriggerFor]="matMenuTriggerFor"
  [disabled]="!_permissionService.hasRights('global_update_users_own')"
  (click)="onClickProfilePicture()"
  class="profile-button eeule-border-color"
  [attr.data-cy]="CY_PROFILEPICTURE_BUTTON_PROFILEPICTURE"
>
  @if(profilePicture$ | async; as imageUrl) {
  <img [src]="imageUrl" alt="Profile Picture" />
  } @else{<img src="../../../../assets/images/eeule_logo.png" alt="Profile Picture" />}
</button>
