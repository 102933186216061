import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, EMPTY, from, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from '../../core/services/appConfig/app-config.service';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { SnackbarService } from '../../core/services/snackbar.service';

interface LoginState {
  isLoading: boolean;
  signInOpen: boolean;
  signUpOpen: boolean;
}

interface LoginFormValues {
  email: string;
  password: string;
  persistentLogin: boolean;
}

@Injectable()
export class LoginStore extends ComponentStore<LoginState> {
  private _authService = inject(AuthService);
  private _appConfigService = inject(AppConfigService);
  private _snackbarService = inject(SnackbarService);

  constructor() {
    super({ isLoading: true, signInOpen: false, signUpOpen: false });
  }

  readonly isLoading = toSignal(
    this.select(state => state.isLoading),
    { initialValue: false }
  );

  readonly signInOpen = toSignal(
    this.select(state => state.signInOpen),
    { initialValue: false }
  );

  readonly signUpOpen = toSignal(
    this.select(state => state.signUpOpen),
    { initialValue: false }
  );

  readonly appConfigLoaded = computed(() => {
    const signInConfig = this._appConfigService.appConfigSignIn();
    const signUpConfig = this._appConfigService.appConfigSignUp();
    return signInConfig !== null && signUpConfig !== null;
  });

  private readonly _loadAppConfigEffect = this.effect(() =>
    combineLatest([this._appConfigService.getLiveAppConfigSignIn(), this._appConfigService.getLiveAppConfigSignUp()]).pipe(
      tap(([signInConfig, signUpConfig]) => {
        this.patchState({
          signInOpen: signInConfig.open,
          signUpOpen: signUpConfig.open,
          isLoading: false,
        });
      })
    )
  );

  readonly submitLoginEffect = this.effect((loginValues$: Observable<LoginFormValues>) =>
    loginValues$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      switchMap(({ email, password, persistentLogin }) =>
        from(this._authService.login(email, password, persistentLogin)).pipe(
          // catchError muss in inner Pipe sein, damit die View weiterläuft
          catchError(error => {
            this.patchState({ isLoading: false });
            const _errorLog = {
              message: 'Error on Login',
              data: {
                email: email,
                originalError: error,
              },
            };
            this._snackbarService.showErrorMessage(`Fehler bei der Anmeldung`, 5000, _errorLog);
            return EMPTY;
          })
        )
      ),
      tap(() => {
        this.patchState({ isLoading: false });
      })
    )
  );
}
