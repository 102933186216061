import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from './analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryService {
  private _history: string[] = [];
  private _maxEntries = 12;

  constructor(private router: Router, private dialog: MatDialog, private _analyticsService: AnalyticsService) {
    // Router-Events überwachen
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._analyticsService.sendPageView(event.urlAfterRedirects);
        this._history.push(event.urlAfterRedirects);
        this._limitEntries();
      }
    });

    // Dialog-Events überwachen
    this.dialog.afterOpened.subscribe(dialogRef => {
      const dialogName = dialogRef.componentInstance?.constructor.name || 'UnknownDialog';
      this._analyticsService.sendEvent('dialog_view', {
        category: 'Dialog',
        label: dialogName,
      });
      this._history.push(dialogName);
      this._limitEntries();
    });
  }

  private _limitEntries() {
    if (this._history.length > this._maxEntries) {
      this._history.shift();
    }
  }

  public getHistory(): string[] {
    return [...this._history]; // Kopie zurückgeben
  }

  public getLastRoute(): string | null {
    return this._history.length > 0 ? this._history[this._history.length - 1] : null;
  }
}
