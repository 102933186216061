<h2 mat-dialog-title>Abonnement wählen</h2>
<mat-dialog-content id="subscription-selection-dialog-content">
  @if(dialogData.pricingTableId){
  <stripe-pricing-table
    [attr.pricing-table-id]="dialogData.pricingTableId"
    [attr.publishable-key]="publishableKey"
    [attr.client-reference-id]="dialogData.clientReferenceId"
    [attr.customer-email]="dialogData.email"
  >
  </stripe-pricing-table>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    [mat-dialog-close]="undefined"
    [attr.data-cy]="CY_SUBSCRIPTIONSELECTIONDIALOG_BUTTON_CANCEL"
    (click)="onClose(CY_SUBSCRIPTIONSELECTIONDIALOG_BUTTON_CANCEL)"
  >
    Abbrechen
  </button>
</mat-dialog-actions>
